import startCase from 'lodash/startCase'

const createChoices = (enumish) => {
  return Object.keys(enumish).map((key) => ({
    id: key,
    name: startCase(enumish[key])
  }))
}

export default createChoices
