import React from 'react'
import {
  ArrayField,
  ArrayInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Filter,
  FormTab,
  List,
  required,
  RichTextField,
  SimpleForm,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
  UrlField
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'
import TimestampsFields from '../../elements/TimestampsFields'

export const Filters = (props) => (
  <Filter {...props}>
    <TextInput
      label='Search To/subject'
      source='name.$regex'
      alwaysOn
    />
    {DateFilters}
  </Filter>
)

export const EmailCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source='name'
        label='Email name'
        type='text'
        validate={required()}
      />
      <TextInput
        source='from'
        helperText='If not set, default value will be used.'
      />
      <TextInput
        source='to'
        label='To'
        type='text'
        validate={required()}
      />
      <TextInput
        source='subject'
        label='Subject'
        type='text'
        validate={required()}
      />
      <TextInput
        source='text'
        label='Text'
        type='text'
        validate={required()}
      />
      <TextInput
        source='subject'
        label='Subject'
        type='text'
        validate={required()}
      />
      <TextInput
        source='html'
        multiline
        fullWidth
        validate={required()}
        helperText='Body of the email.'
      />
      <ArrayInput source='attachments'>
        <SimpleFormIterator>
          <TextInput source='filename' />
          <TextInput source='path' />
        </SimpleFormIterator>
      </ArrayInput>
      {props.id && TimestampsFields}
    </SimpleForm>
  </Create>
)

export const EmailEdit = (props) => (
  <Edit {...props}>
    <TabbedForm
      toolbar={false}
      redirect={false}
    >
      <FormTab label='summary'>
        <TextField
          source='_id'
          label='Id'
        />
        <TextField
          source='name'
          label='Email name'
        />
        <TextField
          source='from'
          label='From'
        />
        <TextField
          source='to'
          label='To'
        />
        <TextField
          source='subject'
          label='Subject'
        />
        <TextField
          source='text'
          label='Text'
        />
        <TextField
          source='subject'
          label='Subject'
        />
        <RichTextField
          source='html'
          label='Html'
        />
        <TextField
          source='status'
          label='Status'
          type='text'
          validate={required()}
        />
        <ArrayField source='attachments'>
          <Datagrid>
            <TextField source='filename' />
            <UrlField source='path' target='_blank' />
          </Datagrid>
        </ArrayField>
        <TextField
          source='errorMessage'
          label='Error message'
          type='text'
        />
        <DateField source='createdAt' showTime />
        <DateField source='updatedAt' showTime />
      </FormTab>
    </TabbedForm>
  </Edit>
)

export const EmailList = (props) => (
  <List
    {...props}
    filters={<Filters />}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField
        source='from'
        label='From'
      />
      <TextField
        source='to'
        label='To'
      />
      <TextField
        source='subject'
        label='Subject'
      />
      <TextField
        source='status'
        label='Status'
      />
      {TimestampsFields}
      <EditButton />
    </Datagrid>
  </List>
)
