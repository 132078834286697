import { Datagrid, DateField, EditButton, Filter, List, TextField, TextInput } from 'react-admin'
import React from 'react'
import DateFilters from '../../elements/DateFilters'

const Filters = (props) => (
  <Filter {...props}>
    <TextInput
      label='Name'
      source='name.$regex'
      alwaysOn
    />
    <TextInput
      label='Email'
      source='email.$regex'
    />
    <TextInput
      label='Phone'
      source='phone.$regex'
    />
    {DateFilters}
  </Filter>
)

const PropertyAdminList = (props) => (
  <List
    {...props}
    filters={<Filters />}
  >
    <Datagrid>
      <TextField
        source='name'
        label='name'
      />
      <TextField
        source='email'
        label='email'
      />
      <TextField
        source='phone'
        label='phone'
      />
      <DateField source='createdAt' showTime />
      <DateField source='updatedAt' showTime />
      <EditButton />
    </Datagrid>
  </List>
)

export default PropertyAdminList
