import React from 'react'
import {
  SimpleForm,
  Create,
  TextField,
  SimpleShowLayout,
  Show,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput, required
} from 'react-admin'
import TimestampsFields from '../../elements/TimestampsFields'
import toRegex from '../../utils/toRegex'

export const PropertyAdminsPropertiesCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        source='propertyAdminId'
        reference='propertyAdmins'
        label='Assign Property Admin'
        allowEmpty
        sort={{
          field: 'name',
          order: 'ASC'
        }}
        filterToQuery={(v) => toRegex('name')(v)}
        validate={required()}
      >
        <AutocompleteInput
          optionText='name'
        />
      </ReferenceInput>
      <ReferenceInput
        source='propertyId'
        reference='properties'
        sort={{
          field: 'name',
          order: 'ASC'
        }}
        filterToQuery={(v) => toRegex('name')(v)}
        validate={required()}
      >
        <AutocompleteInput
          optionText='name'
        />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

export const PropertyAdminsPropertiesShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField
          source='_id'
          label='Id'
        />
        <ReferenceField label='Property Admin' source='propertyAdminId' reference='propertyAdmins'>
          <TextField source='name' />
        </ReferenceField>
        <ReferenceField label='Property' source='propertyId' reference='properties'>
          <TextField source='name' />
        </ReferenceField>
        {TimestampsFields}
      </SimpleShowLayout>
    </Show>
  )
}
