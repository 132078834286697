import React from 'react'
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Filter,
  FormDataConsumer,
  FormTab,
  List,
  ReferenceField,
  ReferenceInput,
  required,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
  useQueryWithStore
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'
import toRegex from '../../utils/toRegex'
import Typography from '@material-ui/core/Typography'
import { EMobileUsersFlatsStatus } from '../mobileUsersFlats/enums'

export const Filters = (props) => (
  <Filter {...props}>
    {DateFilters}
  </Filter>
)

const FlatSelectFiled = (props) => {
  const { data, loading, error, total } = useQueryWithStore({
    type: 'getList',
    resource: 'mobileUsersFlats',
    payload: {
      filter: {
        mobileUserId: props.userId,
        status: EMobileUsersFlatsStatus.ACCEPTED
      },
      pagination: { page: 1, perPage: 1 }
    }
  })

  if (loading || error || !props.userId || !data) return null

  if (!total || !data) {
    return (
      <div>
        <Typography
          variant='body2'
          style={{ color: 'red' }}
        >
          User dosen't have any flats with accepted status
        </Typography>
        <TextInput
          label='Unit'
          source='flatId'
          disabled
          validate={required()}
        />
      </div>
    )
  }

  const flatsIds = data.map((mobileUsersFlat) => mobileUsersFlat.flatId)

  return (
    <ReferenceInput
      label='Flat'
      source='flatId'
      reference='flats'
      filter={{ _id: { $in: flatsIds } }}
      filterToQuery={(v) => toRegex('flatNumber')(v)}
      sort={{
        field: 'flatNumber',
        order: 'ASC'
      }}
      validate={required()}
    >
      <AutocompleteInput
        allowEmpty
        optionText='flatNumber'
        emptyText='clear search'
      />
    </ReferenceInput>
  )
}

export const PushNotificationsCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput
          source='message.data.title'
          label='Title'
          validate={required()}
        />
        <TextInput
          source='message.data.body'
          label='Text'
          validate={required()}
        />
        <TextInput
          source='message.data.notifee'
          label='Notify message'
          validate={required()}
          multiline
        />
        <ReferenceInput
          label='User'
          source='userId'
          reference='mobileUsers'
          filterToQuery={(v) => toRegex('email')(v)}
          sort={{
            field: 'email',
            order: 'ASC'
          }}
          validate={required()}
        >
          <AutocompleteInput
            allowEmpty
            optionText='email'
            emptyText='clear search'
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (<FlatSelectFiled userId={formData.userId} />)
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}

export const PushNotificationsEdit = (props) => (
  <Edit {...props}>
    <TabbedForm
      toolbar={false}
      redirect={false}
    >
      <FormTab label='summary'>
        <TextField
          source='_id'
          label='Id'
        />
        <TextField
          source='message.data.title'
          label='Title'
        />
        <TextField
          source='message.data.body'
          label='Notifee message'
        />
        <TextField
          aria-multiline
          source='message.data.notifee'
          label='Text'
        />
        <ReferenceField
          source='userId'
          label='User'
          reference='mobileUsers'
        >
          <TextField source='email' />
        </ReferenceField>
        <ReferenceField
          source='flatId'
          label='Flat'
          reference='flats'
        >
          <TextField source='flatNumber' />
        </ReferenceField>
        <TextField
          source='status'
        />
        <DateField source='createdAt' showTime />
        <DateField source='updatedAt' showTime />
      </FormTab>
    </TabbedForm>
  </Edit>
)

export const PushNotificationsList = (props) => (
  <List
    {...props}
    filters={<Filters />}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField
        label='Title'
        source='message.data.title'
      />
      <TextField
        label='Text'
        source='message.data.body'
      />
      <TextField
        source='status'
      />
      <ReferenceField
        label='User'
        source='userId'
        reference='mobileUsers'
      >
        <TextField source='email' />
      </ReferenceField>
      <DateField source='createdAt' showTime />
      <DateField source='updatedAt' showTime />
      <EditButton />
    </Datagrid>
  </List>
)
