import { Datagrid, EditButton, Filter, List, ReferenceField, TextField, TextInput } from 'react-admin'
import TimestampsFields from '../../../elements/TimestampsFields'
import React from 'react'
import DateFilters from '../../../elements/DateFilters'

export const Filters = (props) => (
  <Filter {...props}>
    <TextInput
      label='ID'
      source='_id.$regex'
      alwaysOn
    />
    {DateFilters}
  </Filter>
)

const AppAccessList = (props) => (
  <List
    {...props}
    filters={<Filters />}
    filter={{
      'status[$ne]': 'archived'
    }}
    bulkActionButtons={false}
  >
    <Datagrid>
      <ReferenceField
        source='hostId'
        label='Host'
        reference='mobileUsers'
      >
        <TextField source='email' />
      </ReferenceField>
      <ReferenceField
        source='guestId'
        label='Guest'
        reference='mobileUsers'
      >
        <TextField source='email' />
      </ReferenceField>
      <ReferenceField
        source='flatId'
        label='Property'
        reference='flats'
      >
        <ReferenceField
          source='propertyId'
          reference='properties'
        >
          <TextField source='name' />
        </ReferenceField>
      </ReferenceField>
      <ReferenceField
        source='flatId'
        label='Flat Number'
        reference='flats'
      >
        <TextField source='flatNumber' />
      </ReferenceField>
      {TimestampsFields}
      <EditButton />
    </Datagrid>
  </List>
)

export default AppAccessList
