import React from 'react'
import { DeleteWithConfirmButton, SaveButton, Toolbar as RAToolbar } from 'react-admin'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}))

const Toolbar = props => {
  const classes = useStyles()
  return (
    <RAToolbar {...props} className={classes.flex}>
      <SaveButton />
      {!props.hideDelete && <DeleteWithConfirmButton />}
    </RAToolbar>
  )
}

export default Toolbar
