import React, { useState } from 'react'
import {
  DateField,
  DateInput,
  FormTab,
  Labeled,
  SelectField,
  TabbedForm,
  TextField,
  TextInput,
  useNotify,
  useRecordContext,
} from 'react-admin'

import TimestampsFields from '../../elements/TimestampsFields'
import Change from '../../elements/Change'
import Toolbar from '../../elements/Layout/Toolbar'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { Box, IconButton, Tooltip } from '@material-ui/core'

export const LicenseField = (props) => {
  const notify = useNotify()
  const [showPassword, setShowPassword] = useState(false)
  const { record } = useRecordContext(props)
  const renderField = () => <Box display='flex' alignItems='center'>
    <Tooltip
      title='Press to copy'
      onClick={() => {
        navigator.clipboard.writeText(record?.[props.source])
        notify('License key copied!', 'info')
      }}
      style={{ cursor: 'pointer'}}
      color='red'
    >
      <span>{!showPassword ? record?.[props.source]?.replace(/./g, '*') : record?.[props.source]}</span>
    </Tooltip>
    <IconButton onClick={() => setShowPassword(value => !value)} style={{ marginLeft: 8 }} size='small'>
      {!showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
    </IconButton>
  </Box>

  if (props.labeled) {
    return <Labeled label={props.label}>{renderField()}</Labeled>
  }

  return renderField()
}

export const LicensesEdit = (props) => (
  <Change {...props}>
    <TabbedForm
      toolbar={props.id && <Toolbar />}
      redirect={!props.id && 'edit'}
    >
      <FormTab label='summary'>
        {props.id && (
          <TextField
            source='_id'
            label='Id'
          />
        )}
        {props.id && (
          <LicenseField
            labeled
            source='licenseCode'
            label='License Key'
          />
        )}
        {props.id && (
          <SelectField
            choices={[
              { id: 0, name: 'Pending' },
              { id: 1, name: 'Active' },
              { id: 2, name: 'Revoked' },
              { id: 3, name: 'Expired' },
              { id: 4, name: 'Archived' },
            ]}
            source='status'
            label='Status'
          />
        )}
        <TextInput
          source='organizationName'
          label='Organization Name'
        />
        <DateInput
          source='expiryDate'
          label='Expiry Date'
        />
        {props.id && (
          <TextInput
            source='apiUrl'
            label='API URL'
            showTime
          />
        )}
        {props.id && (
          <DateField
            source='activatedAt'
            label='Activated at'
            showTime
          />
        )}
        {props.id && (
          <TextField
            source='activationIP'
            label='Activation IP'
          />
        )}
        {props.id && (
          <DateField
            source='lastCheckedAt'
            label='Last Checked at'
            showTime
          />
        )}
        {props.id && (
          <DateField
            source='nextCheckedAt'
            label='Next Checked at'
            showTime
          />
        )}
        {props.id && TimestampsFields}
      </FormTab>
    </TabbedForm>
  </Change>
)
