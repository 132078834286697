import React from 'react'
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  FormTab,
  List,
  ReferenceField,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'
import TimestampsFields from '../../elements/TimestampsFields'
import toRegex from '../../utils/toRegex'
import Toolbar from '../../elements/Layout/Toolbar'
import ResetPasswordButton from '../../elements/ResetPasswordButton'

export const Filters = (props) => (
  <Filter {...props}>
    <TextInput
      label='Email'
      source='email.$regex'
      alwaysOn
    />
    <ReferenceInput
      label='Delivery company'
      source='companyId'
      reference='deliveryCompanies'
      sort={{ field: 'name', order: 'ASC' }}
      alwaysOn
    >
      <SelectInput optionText='name' />
    </ReferenceInput>
    {DateFilters}
  </Filter>
)

export const DeliveryAdminCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source='email'
        label='Email'
        type='email'
        validate={required()}
      />
      <ReferenceInput
        label='Delivery company'
        source='companyId'
        reference='deliveryCompanies'
        sort={{ field: 'name', order: 'ASC' }}
        filterToQuery={(v) => toRegex('name')(v)}
        validate={required()}
      >
        <AutocompleteInput optionText='name' />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

export const DeliveryAdminEdit = (props) => {
  return (
    <Edit {...props}>
      <TabbedForm
        toolbar={<Toolbar />}
        redirect={false}
      >
        <FormTab label='summary'>
          <TextField
            source='_id'
            label='Id'
          />
          <TextInput
            source='email'
            label='Email'
            type='text'
            validate={required()}
          />
          <ResetPasswordButton />
          <ReferenceInput
            label='Delivery company'
            key='companyId'
            source='companyId'
            reference='deliveryCompanies'
            sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={(v) => toRegex('name')(v)}
            validate={required()}
          >
            <AutocompleteInput optionText='name' emptyText='clear search' />
          </ReferenceInput>
          <BooleanInput
            source='isVerified'
            label='Verified'
          />
          {TimestampsFields}
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export const DeliveryAdminList = (props) => (
  <List
    {...props}
    filters={<Filters />}
  >
    <Datagrid>
      <TextField
        source='email'
        label='email'
      />
      <ReferenceField
        label='Delivery company'
        source='companyId'
        reference='deliveryCompanies'
      >
        <TextField source='name' />
      </ReferenceField>
      {TimestampsFields}
      <EditButton />
    </Datagrid>
  </List>
)
