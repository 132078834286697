import {
  Datagrid,
  DeleteButton,
  EditButton,
  FormTab,
  Pagination,
  ReferenceManyField,
  TextField,
  TextInput,
  useCreate,
  useNotify,
  useRefresh,
} from 'react-admin'
import React from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { useFormState } from 'react-final-form'
import TimestampsFields from '../../../elements/TimestampsFields'

const AdminsTab = ({ ...props }) => {
  const state = useFormState()
  const notify = useNotify()
  const refresh = useRefresh()
  const [create] = useCreate(
    'communityAdmins',
    {
      name: state.values.communityAdminName,
      email: state.values.communityAdminEmail,
      phone: state.values.communityAdminPhone,
      communityId: state.values.id
    }
  )

  return (
    <FormTab {...props} label='admins'>
      <Box style={{ width: 400, gap: 16 }} display="flex">
        <TextInput
          source='communityAdminName'
          label='Name'
          placeholder="Enter name"
        />
        <TextInput
          source='communityAdminEmail'
          label='Email'
          placeholder="Enter email"
        />
        <TextInput
          type="phone"
          source='communityAdminPhone'
          label='Phone number'
          placeholder="Enter phone"
        />
      </Box>
      <Button
        onClick={() => {
          create({}, {
            onSuccess: () => {
              refresh()
            },
            onFailure: (error) => {
              let mes = error.message
              if (error.code === 409) {
                mes = 'Community admin already exists.'
              }
              notify(mes, 'error')
            }
          })
        }}
        disabled={!state.values.communityAdminEmail || !state.values.communityAdminPhone}
        color='primary'
        variant='contained'
      >
        Create
      </Button>

      <Typography variant='body1' style={{ margin: '50px 0 20px' }}>
        Community admins:
      </Typography>

      <ReferenceManyField
        addLabel={false}
        fullWidth
        reference='communityAdmins'
        target='communityId'
        sort={{
          field: 'createdAt',
          order: 'DESC'
        }}
        pagination={<Pagination />}
      >
        <Datagrid>
          <TextField
            source='name'
            label='Name'
          />
          <TextField
            source='email'
            label='Email'
          />
          <TextField
            source='phone'
            label='Phone number'
          />
          {TimestampsFields}
          <EditButton />
          <DeleteButton redirect={false} />
        </Datagrid>
      </ReferenceManyField>
    </FormTab>
  )
}

export default AdminsTab
