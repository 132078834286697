import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, CardContent, CircularProgress, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { styled } from '@material-ui/styles'
import { Form } from 'react-final-form'
import { required, TextInput, useLogin, useNotify, useRedirect, useSafeSetState, useTranslate, } from 'react-admin'
import { debounce } from 'lodash'

const OTPForm = (props) => {
  const { className } = props
  const [loading, setLoading] = useSafeSetState(false)
  const translate = useTranslate()
  const login = useLogin()
  const redirect = useRedirect()
  const notify = useNotify()
  const location = useLocation()

  const submit = (values) => {
    setLoading(true)
    login({
      username: location.state?.username,
      password: location.state?.password,
      otp: values.otp
    })
      .catch(() => notify('messages.verifyToken.invalidOrExpiredVerifyCode'))
      .finally(() => setLoading(false))
  }

  const backToLogin = (e) => {
    e.preventDefault()
    redirect('/login')
  }

  useEffect(() => {
    if (!location.state?.username || !location.state?.password) {
      redirect('/login')
    }
  }, [location.state])

  return (
    <Form
      onSubmit={submit}
    >
      {props => (
        <StyledForm
          onSubmit={(e) => e.preventDefault()}
          className={className}
        >
          <Typography style={{ textAlign: 'center' }} component='h1' variant='h6'>
            Two-factor authentication
          </Typography>
          <CardContent className={OTPFormClasses.content}>
            <TextInput
              source='otp'
              variant='standard'
              autoFocus
              id='otp'
              name='otp'
              validate={required('messages.verifyToken.otp.required')}
              fullWidth
              label={translate('messages.verifyToken.otp.label')}
            />

            <Button
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
              onClick={debounce(props.handleSubmit, 300)}
              disabled={props.submitting || props.invalid || loading}
              className={OTPFormClasses.button}
            >
              {loading ? (
                <CircularProgress
                  className={OTPFormClasses.icon}
                  size={19}
                  thickness={3}
                />
              ) : (
                translate('ra.auth.sign_in')
              )}
            </Button>
            <Button
              fullWidth
              variant='outlined'
              color='primary'
              onClick={backToLogin}
              className={OTPFormClasses.button}
            >
              Back to Login
            </Button>
          </CardContent>
        </StyledForm>
      )}
    </Form>
  )
}


const PREFIX = 'RaOTPForm'

export const OTPFormClasses = {
  content: `${PREFIX}-content`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
}

const StyledForm = styled('form', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(() => ({
  [`& .${OTPFormClasses.content}`]: {
    width: 300,
  },
  [`& .${OTPFormClasses.button}`]: {
    marginTop: 16,
  },
  [`& .${OTPFormClasses.icon}`]: {
    margin: 0.8 * 3,
  },
}))

OTPForm.propTypes = {
  redirectTo: PropTypes.string,
}

export default OTPForm
