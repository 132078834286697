import React, { useEffect } from 'react'
import {
  AutocompleteInput,
  Create,
  DateField,
  Edit,
  FormTab,
  ReferenceField,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  useNotify
} from 'react-admin'

import FilteredReferenceInput from '../../elements/inputs/FilteredReferenceInput'
import { EMobileUsersFlatsStatus, statuses } from './enums'
import toRegex from '../../utils/toRegex'
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles'
import { createMuiTheme } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { useMutation, useRedirect } from 'ra-core'
import debounce from 'lodash/debounce'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles(
  theme => ({
    rangeTime: {
      paddingLeft: theme.spacing(2)
    },
    rangeTimeGroup: {
      paddingLeft: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    flex: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  })
)

const redTheme = createMuiTheme({ palette: { primary: red } })

const EditToolbar = props => {
  const classes = useStyles()
  const notify = useNotify()
  const redirect = useRedirect()
  const [changeStatus, { error }] = useMutation({
    type: 'update',
    resource: props.resource,
    payload: {
      id: props.record.id,
      data: {
        status: EMobileUsersFlatsStatus.DECLINED
      }
    }
  }, {
    onSuccess: () => redirect('/mobileUsersFlats')
  })

  const declineMobileUsersFlats = debounce(changeStatus, 300)

  useEffect(() => {
    if (error?.message) {
      console.error(error)
      notify(error.message, 'warning')
    }
  }, [error, notify])

  return (
    <Toolbar {...props} className={classes.flex}>
      <SaveButton />
      <MuiThemeProvider theme={redTheme}>
        <Button startIcon={<DeleteIcon />} color='primary' variant='text' redirect={false} onClick={declineMobileUsersFlats}>
          Decline
        </Button>
      </MuiThemeProvider>
    </Toolbar>
  )
}

export const MobileUsersFlatsCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        source='mobileUserId'
        reference='mobileUsers'
        label='Mobile User'
        filterToQuery={(v) => toRegex('email')(v)}
        validate={required()}
      >
        <AutocompleteInput optionText='email' />
      </ReferenceInput>
      <ReferenceInput
        source='propertyId'
        label='Property'
        reference='properties'
        filterToQuery={(v) => toRegex('name')(v)}
        validate={required()}
      >
        <AutocompleteInput optionText='name' />
      </ReferenceInput>
      <FilteredReferenceInput
        label='Unit'
        key='flatId'
        source='flatId'
        reference='flats'
        sort={{ field: 'flatNumber', order: 'ASC' }}
        validate={required()}
        filterSource='propertyId'
        filterDest='propertyId'
      >
        <SelectInput optionText='flatNumber' />
      </FilteredReferenceInput>
      <SelectInput
        source='status'
        label='Status'
        choices={statuses}
        validate={required()}
      />
      <TextInput
        source='label'
        label='Label'
      />
    </SimpleForm>
  </Create>
)

export const MobileUsersFlatsEdit = (props) => (
  <Edit {...props}>
    <TabbedForm
      redirect={false}
      toolbar={<EditToolbar />}
    >
      <FormTab label='summary'>
        <TextField
          source='_id'
          label='Id'
        />
        <ReferenceField
          source='mobileUserId'
          label='Mobile User'
          reference='mobileUsers'
        >
          <TextField source='phone' />
        </ReferenceField>
        <ReferenceField
          source='flatId'
          label='Flat'
          reference='flats'
        >
          <TextField source='flatNumber' />
        </ReferenceField>
        <SelectInput
          source='status'
          label='Status'
          choices={statuses}
          validate={required()}
        />
        <TextInput
          source='label'
          label='Label'
        />
        <DateField
          source='createdAt'
          label='Created at'
          showTime
        />
        <DateField
          source='updatedAt'
          label='Updated at'
          showTime
        />
      </FormTab>
    </TabbedForm>
  </Edit>
)
