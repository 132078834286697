import React from 'react'

import {
  ReferenceInput,
  useInput
} from 'react-admin'

const FilteredReferenceInput = ({
  filterSource,
  filterDest,
  filters,
  children,
  ...inputProps
}) => {
  const { input: { value: filterValue } } = useInput({ source: filterSource })
  const hasFilterValue = Boolean(filterValue)
  const filtersWithParents =
    hasFilterValue ? { [filterDest]: filterValue, ...filters } : filters

  return (
    <ReferenceInput
      {...inputProps}
      filter={filtersWithParents}
      disabled={!hasFilterValue}
    >
      {children}
    </ReferenceInput>
  )
}

export default FilteredReferenceInput
