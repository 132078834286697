import {
  Datagrid,
  DateField, DeleteButton,
  FormTab,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  EditButton,
  TextField, AutocompleteInput,
  ReferenceInput,
  useCreate, useRefresh, useNotify, SelectField
} from 'react-admin'
import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { useFormState } from 'react-final-form'
import toRegex from '../../utils/toRegex'
import { statuses } from '../mobileUsersFlats/enums'
import { Link } from 'react-router-dom'

const EditConnectedButton = (props) => <EditButton {...props} basePath='/mobileUsers' />

const StatusLink = (props) => {
  return (
    <Button
      component={Link}
      to={{
        pathname: `/mobileUsersFlats/${props.record.id}`
      }}
      color='primary'
    >
      <SelectField
        {...props}
        source='status'
        choices={statuses}
      />
    </Button>
  )
}

const UsersTab = ({ ...props }) => {
  const state = useFormState()
  const notify = useNotify()
  const refresh = useRefresh()
  const [create] = useCreate(
    'mobileUsersFlats',
    {
      mobileUserId: state.values.mobileUserId,
      flatId: state.values.id
    }
  )

  return (
    <FormTab {...props} label='mobile users'>
      <ReferenceInput
        source='mobileUserId'
        reference='mobileUsers'
        label='Assign mobile user'
        allowEmpty
        sort={{
          field: 'email',
          order: 'ASC'
        }}
        filterToQuery={(v) => toRegex('email')(v)}
      >
        <AutocompleteInput
          optionText='email'
          emptyText='clear search'
        />
      </ReferenceInput>
      <Button
        onClick={() => {
          create({}, {
            onSuccess: () => {
              refresh()
            },
            onFailure: (error) => {
              let mes = error.message
              if (error.code === 409) {
                mes = 'User already exists.'
              }
              notify(mes, 'error')
            }
          })
        }}
        disabled={!state.values.mobileUserId}
        color='primary'
        variant='contained'
      >
        Assign
      </Button>

      <Typography variant='body1' style={{ margin: '50px 0 20px' }}>
        Assigned mobile users:
      </Typography>

      <ReferenceManyField
        addLabel={false}
        fullWidth
        reference='mobileUsersFlats'
        target='flatId'
        pagination={<Pagination />}
      >
        <Datagrid>
          <ReferenceField label='Email' source='mobileUserId' reference='mobileUsers' link={false}>
            <TextField source='email' />
          </ReferenceField>
          <ReferenceField label='Name' source='mobileUserId' reference='mobileUsers' link={false}>
            <TextField source='name' />
          </ReferenceField>
          <StatusLink label='status' />
          <ReferenceField label='CreatedAt' source='mobileUserId' reference='mobileUsers' link={false}>
            <DateField source='createdAt' showTime />
          </ReferenceField>
          <ReferenceField label='UpdatedAt' source='mobileUserId' reference='mobileUsers' link={false}>
            <DateField source='updatedAt' showTime />
          </ReferenceField>

          <ReferenceField label='' source='mobileUserId' reference='mobileUsers'>
            <EditConnectedButton />
          </ReferenceField>
          <DeleteButton label='Unlink' redirect={false} />
        </Datagrid>
      </ReferenceManyField>
    </FormTab>
  )
}

export default UsersTab
