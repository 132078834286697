import React, { createElement, useState } from 'react'
import { Collapse, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core'
import { ExpandLess, ExpandMore, Settings } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  mainText: {
    color: theme.palette.text.secondary,
    padding: '2px 16px'
  },
  activeText: {
    color: theme.palette.text.primary,
    padding: '2px 16px'
  },
  icon: { minWidth: theme.spacing(5) }
}))

const Submenu = ({ icon, children, label, active, defaultOpen = false }) => {
  const classes = useStyles()
  const [isOpen, setOpen] = useState(defaultOpen)
  return (
    <>
      <ListItem
        button
        onClick={() => setOpen(!isOpen)}
        className={active ? classes.activeText : classes.mainText}
      >
        <ListItemIcon className={classes.icon}>
          {createElement(icon || Settings)}
        </ListItemIcon>
        <ListItemText primary={label} />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isOpen} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  )
}

export default Submenu
