import React from 'react'
import { Datagrid, EditButton, Filter, FormTab, List, required, TabbedForm, TextField, TextInput, BooleanInput, BooleanField } from 'react-admin'

import DateFilters from '../../elements/DateFilters'
import TimestampsFields from '../../elements/TimestampsFields'
import CloudinaryField from '../../elements/CloudinaryField'
import Toolbar from '../../elements/Layout/Toolbar'
import Change from '../../elements/Change'
import DeliverersTab from './DeliverersTab'
import AdminsTab from './AdminsTab'

export const Filters = (props) => (
  <Filter {...props}>
    <TextInput
      label='Name'
      source='name.$regex'
      alwaysOn
    />
    {DateFilters}
  </Filter>
)

export const DeliveryCompanyChange = (props) => (
  <Change {...props}>
    <TabbedForm
      toolbar={props.id && <Toolbar hideDelete />}
      redirect={!props.id ? 'edit' : false}
    >
      <FormTab label='summary'>
        {props.id && (
          <TextField
            source='_id'
            label='Id'
          />
        )}
        <TextInput
          source='name'
          label='Name'
          type='text'
          validate={required()}
        />
        {
          props.id && (
            <TextField
              source='refNumber'
              label='Reference Number'
            />
          )
        }
        <CloudinaryField
          source='photo'
          label='Logo'
          required
        />
        <TextInput
          source='maxUsers'
          label='Max Users'
          type='number'
          validate={required()}
        />
        <BooleanInput
          source='showInEntranceApp'
          label='Show In Entrance App'
        />
        {props.id && TimestampsFields}
      </FormTab>
      {props.id && <DeliverersTab />}
      {props.id && <AdminsTab />}
    </TabbedForm>

  </Change>
)

export const DeliveryCompanyList = (props) => (
  <List
    {...props}
    filters={<Filters />}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField
        source='name'
        label='Name'
      />
      <TextField
        source='refNumber'
        label='Reference Number'
      />
      <BooleanField source='showInEntranceApp' label='Show In Entrance App' />
      {TimestampsFields}
      <EditButton />
    </Datagrid>
  </List>
)
