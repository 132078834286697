import React from 'react'
import {
  AutocompleteInput,
  FormTab,
  NumberField,
  NumberInput,
  ReferenceInput,
  required,
  TabbedForm,
  TextField
} from 'react-admin'

import TimestampsFields from '../../elements/TimestampsFields'
import toRegex from '../../utils/toRegex'
import Change from '../../elements/Change'
import Toolbar from '../../elements/Layout/Toolbar'

export const SubscriptionChange = (props) => (
  <Change {...props}>
    <TabbedForm
      toolbar={props.id && <Toolbar />}
      redirect={!props.id && 'edit'}
    >
      <FormTab label='summary'>
        {props.id && (
          <TextField
            source='_id'
            label='Id'
          />
        )}

        <ReferenceInput
          source='propertyId'
          label='Property'
          reference='properties'
          sort={{ field: 'name', order: 'ASC' }}
          filterToQuery={(v) => toRegex('name')(v)}
          validate={required()}
        >
          <AutocompleteInput optionText='name' />
        </ReferenceInput>
        <NumberInput
          source='maxUsers'
          label='Max users'
          validate={required()}
          min={0}
        />
        {props.id && (
          <NumberField
            source='activeUsers'
            label='Active users'
          />
        )}
        {props.id && TimestampsFields}
      </FormTab>
    </TabbedForm>
  </Change>
)
