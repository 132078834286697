import Button from '@material-ui/core/Button'
import React, { useState } from 'react'
import { useNotify, useRefresh, useUpdateLoading } from 'react-admin'

const ResetPasswordButton = ({ record }) => {
  const updateLoading = useUpdateLoading()
  const notify = useNotify()
  const refresh = useRefresh()
  const [loading, setLoading] = useState(false)
  const resetPassword = () => {
    setLoading(true)
    updateLoading.startLoading()
    fetch(`${process.env.REACT_APP_API_URL}/resetPassword/${record?.id}`, { method: 'POST' })
      .then(() => {
        notify('User password is changed', 'success');
        refresh()
      })
      .catch((e) => {
        console.error(e)
        notify(e.message, 'warning')
      })
      .finally(() => {
        setLoading(false)
        updateLoading.stopLoading()
      })
  }

  return <Button
    variant='contained'
    color={'primary'}
    disabled={loading}
    onClick={() => resetPassword()}
  >
    Reset password
  </Button>
}

export default ResetPasswordButton
