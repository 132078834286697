import React from 'react'
import {
  SimpleForm,
  Create,
  TextInput,
  Edit,
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  TabbedForm,
  FormTab,
  SelectInput,
  FormDataConsumer,
  required
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'
import { validatePhone } from '../../validators'
import TimestampsFields from '../../elements/TimestampsFields'

export const Filters = (props) => (
  <Filter {...props}>
    <TextInput
      label='Email'
      source='email.$regex'
      alwaysOn
    />
    <TextInput
      label='Phone'
      source='phone.$regex'
      alwaysOn
    />
    <SelectInput
      source='type'
      choices={[
        { id: 'mobileUsers', name: 'mobileUsers' },
        { id: 'admins', name: 'admins' }
      ]}
      alwaysOn
    />
    {DateFilters}
  </Filter>
)

export const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput
        source='type'
        choices={[
          { id: 'mobileUsers', name: 'mobileUsers' },
          { id: 'admins', name: 'admins' }
        ]}
        alwaysOn
      />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.type === 'admins' && <TextInput
            source='email'
            label='Email'
            type='email'
            validate={required()}
                                        />}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.type === 'mobileUsers' && <TextInput
            source='phone'
            label='Phone'
            type='phone'
            validate={[required(), validatePhone()]}
                                             />}
      </FormDataConsumer>

      <TextInput
        source='password'
        label='Password'
        type='password'
        validate={required()}
      />
    </SimpleForm>
  </Create>
)

export const UserEdit = (props) => (
  <Edit {...props}>
    <TabbedForm
      redirect={false}
    >
      <FormTab label='summary'>
        <TextField
          source='_id'
          label='Id'
        />
        <TextInput
          source='email'
          label='Email'
          type='text'
          validate={required()}
        />
        <TextInput
          source='password'
          label='Password'
          type='password'
          validate={required()}
        />
        {TimestampsFields}
      </FormTab>
    </TabbedForm>
  </Edit>
)

export const UserList = (props) => (
  <List
    {...props}
    filters={<Filters />}
  >
    <Datagrid>
      <TextField
        source='email'
        label='email'
      />
      <TextField
        source='phone'
        label='phone'
      />
      <TextField
        source='type'
        label='type'
      />
      {TimestampsFields}
      <EditButton />
    </Datagrid>
  </List>
)
