import { Layout as RALayout } from 'react-admin'
import React from 'react'
import Menu from './Menu'

const Layout = (props) => (
  <RALayout
    {...props}
    menu={Menu}
  />
)

export default Layout
