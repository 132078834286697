import { AutocompleteInput, ReferenceInput, required, TextInput } from 'react-admin'
import toRegex from '../../utils/toRegex'
import React from 'react'
import { useForm, useFormState } from 'react-final-form'

const AutocompleteFlat = (props) => {
  const form = useForm()
  const state = useFormState()

  return (
    <>
      <ReferenceInput
        source='propertyId'
        label='Property'
        reference='properties'
        sort={{ field: 'name', order: 'ASC' }}
        filterToQuery={(v) => toRegex('name')(v)}
        validate={!props.id && required()}
        onChange={() => form.change('flatId', undefined)}
        {...props}
      >
        <AutocompleteInput optionText='name' emptyText='clear search' />
      </ReferenceInput>
      <ReferenceInput
        source='flatId'
        label='Flat'
        reference='flats'
        sort={{ field: 'flatNumber', order: 'ASC' }}
        filter={{ propertyId: state.values.propertyId }}
        filterToQuery={(v) => toRegex('flatNumber')(v)}
        validate={required()}
        disabled={!state.values.propertyId}
        {...props}
      >
        <AutocompleteInput optionText='flatNumber' emptyText='clear search' />
      </ReferenceInput>
    </>
  )
}

const AccessFields = (props) => {
  return (
    <>
      <ReferenceInput
        source='hostId'
        label='Host'
        reference='mobileUsers'
        sort={{ field: 'email', order: 'ASC' }}
        filterToQuery={(v) => toRegex('email')(v)}
        validate={required()}
        {...props}
      >
        <AutocompleteInput optionText='email' emptyText='clear search' />
      </ReferenceInput>

      <AutocompleteFlat {...props} />

      <ReferenceInput
        source='guestId'
        label='Guest'
        reference='mobileUsers'
        sort={{ field: 'email', order: 'ASC' }}
        filterToQuery={(v) => toRegex('email')(v)}
        {...props}
      >
        <AutocompleteInput optionText='email' emptyText='clear search' />
      </ReferenceInput>
      <TextInput
        source='accessCode'
        label='Access Code'
        type='text'
        {...props}
      />
    </>
  )
}
export default AccessFields
