import { DateField, Edit, FormTab, required, TabbedForm, TextField, TextInput } from 'react-admin'
import Toolbar from '../../elements/Layout/Toolbar'
import { validatePhone } from '../../validators'
import ResetPasswordButton from '../../elements/ResetPasswordButton'
import React from 'react'

export const CommunityAdminEdit = (props) => {
  return (
    <Edit {...props}>
      <TabbedForm
        redirect={false}
        toolbar={<Toolbar />}
      >
        <FormTab label='summary'>
          <TextField
            source='_id'
            label='Id'
          />
          <TextInput
            source='name'
            label='Name'
            type='text'
            validate={required()}
          />
          <TextInput
            source='email'
            label='Email'
            type='email'
            validate={required()}
          />
          <TextInput
            source='phone'
            label='Phone'
            type='text'
            validate={validatePhone()}
          />
          <ResetPasswordButton />
          <DateField
            source='createdAt'
            label='Created at'
            showTime
          />
          <DateField
            source='updatedAt'
            label='Updated at'
            showTime
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
