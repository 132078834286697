import { Datagrid, EditButton, FormTab, Pagination, ReferenceManyField, TextField } from 'react-admin'
import React from 'react'
import { Button, Typography } from '@material-ui/core'
import TimestampsFields from '../../elements/TimestampsFields'
import { Link } from 'react-router-dom'
import { useFormState } from 'react-final-form'

const AdminsTab = ({ ...props }) => {
  const state = useFormState()
  return (
    <FormTab {...props} label='Admins'>
      <Button
        component={Link}
        to={{
          pathname: '/deliveryAdmins/create',
          state: {
            record: {
              companyId: state.values.id
            }
          }
        }}
        color='primary'
        variant='contained'
      >
        Create New
      </Button>

      <Typography variant='body1' style={{ margin: '50px 0 20px' }}>
        Delivery Admins:
      </Typography>

      <ReferenceManyField
        addLabel={false}
        fullWidth
        reference='deliveryAdmins'
        target='companyId'
        sort={{
          field: 'createdAt',
          order: 'DESC'
        }}
        pagination={<Pagination />}
      >
        <Datagrid>
          <TextField source='email' />
          {TimestampsFields}
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
    </FormTab>
  )
}

export default AdminsTab
