import React from 'react'
import {
  AutocompleteInput,
  Datagrid,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextField
} from 'react-admin'

import DateFilters from '../../elements/DateFilters'
import TimestampsFields from '../../elements/TimestampsFields'
import Toolbar from '../../elements/Layout/Toolbar'
import toRegex from '../../utils/toRegex'
import startCase from 'lodash/startCase'
import { EDeliveryCompanyDelivererStatus } from './enums'
import Change from '../../elements/Change'

export const Filters = (props) => (
  <Filter {...props}>
    <ReferenceInput
      source='mobileUserId'
      label='Mobile User'
      reference='mobileUsers'
      alwaysOn
    >
      <SelectInput optionText='name' />
    </ReferenceInput>
    {DateFilters}
  </Filter>
)

export const DeliveryCompanyDelivererChange = (props) => (
  <Change {...props}>
    <SimpleForm
      toolbar={props.id && <Toolbar />}
      redirect={!props.id ? 'edit' : false}
    >
      {props.id && (
        <TextField
          source='_id'
          label='Id'
        />
      )}
      <ReferenceInput
        source='mobileUserId'
        label='Mobile User'
        reference='mobileUsers'
        sort={{ field: 'email', order: 'ASC' }}
        filterToQuery={(v) => toRegex('email')(v)}
        validate={required()}
      >
        <AutocompleteInput optionText='email' emptyText='clear search' />
      </ReferenceInput>
      <ReferenceInput
        source='deliveryCompanyId'
        label='Delivery company'
        reference='deliveryCompanies'
        sort={{ field: 'name', order: 'ASC' }}
        filterToQuery={(v) => toRegex('name')(v)}
        validate={required()}
      >
        <AutocompleteInput optionText='name' emptyText='clear search' />
      </ReferenceInput>
      <SelectInput
        choices={Object.keys(EDeliveryCompanyDelivererStatus).map((key) => ({
          id: EDeliveryCompanyDelivererStatus[key],
          name: startCase(key)
        }))}
        source='status'
      />
      {props.id && TimestampsFields}
    </SimpleForm>
  </Change>
)

export const DeliveryCompanyDelivererList = (props) => (
  <List
    {...props}
    filters={<Filters />}
  >
    <Datagrid>
      <ReferenceField
        source='mobileUserId'
        label='Mobile User'
        reference='mobileUsers'
      >
        <TextField source='email' />
      </ReferenceField>
      <ReferenceField
        source='deliveryCompanyId'
        label='Delivery company'
        reference='deliveryCompanies'
      >
        <TextField source='name' />
      </ReferenceField>
      {TimestampsFields}
      <EditButton />
    </Datagrid>
  </List>
)
