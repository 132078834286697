import React from 'react'
import {
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Filter,
  FormTab,
  List,
  required,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'
import { validatePhone } from '../../validators'
import ResetPasswordButton from '../../elements/ResetPasswordButton'

export const Filters = (props) => (
  <Filter {...props}>
    <TextInput
      label='Name'
      source='name.$regex'
      alwaysOn
    />
    <TextInput
      label='Email'
      source='email.$regex'
    />
    <TextInput
      label='Phone'
      source='phone.$regex'
    />
    {DateFilters}
  </Filter>
)

export const PropertyManagerCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source='name'
        label='Name'
        type='text'
        validate={required()}
      />
      <TextInput
        source='email'
        label='Email'
        type='email'
        validate={required()}
      />
      <TextInput
        source='phone'
        label='Phone'
        type='text'
        validate={validatePhone()}
      />
    </SimpleForm>
  </Create>
)

export const PropertyManagerEdit = (props) => {
  return (
    <Edit {...props}>
      <TabbedForm
        redirect={false}
      >
        <FormTab label='summary'>
          <TextField
            source='_id'
            label='Id'
          />
          <TextInput
            source='name'
            label='Name'
            type='text'
            validate={required()}
          />
          <TextInput
            source='email'
            label='Email'
            type='email'
            validate={required()}
          />
          <TextInput
            source='phone'
            label='Phone'
            type='text'
            validate={validatePhone()}
          />
          <ResetPasswordButton />
          <BooleanInput
            source='isVerified'
            label='Verified'
            validate={required()}
          />
          <TextField
            source='verifyToken'
            label='Verify Token'
            type='text'
            validate={required()}
          />
          <TextField
            source='verifyShortToken'
            label='Verify Short Token'
            type='text'
            validate={required()}
          />
          <DateField
            source='verifyExpires'
            label='Verify Expires'
            validate={required()}
          />
          <TextField
            source='resetToken'
            label='Reset Token'
            type='text'
            validate={required()}
          />
          <TextField
            source='resetShortToken'
            label='Reset Short Token'
            type='text'
            validate={required()}
          />
          <DateField
            source='resetExpires'
            label='Reset Expires'
            validate={required()}
            showTime
          />
          <DateField
            source='createdAt'
            label='Created at'
            showTime
          />
          <DateField
            source='updatedAt'
            label='Updated at'
            showTime
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export const PropertyManagerList = (props) => (
  <List
    {...props}
    filters={<Filters />}
  >
    <Datagrid>
      <TextField source='name' />
      <TextField source='email' />
      <TextField source='phone' />
      <DateField source='createdAt' showTime />
      <DateField source='updatedAt' showTime />
      <EditButton />
    </Datagrid>
  </List>
)
