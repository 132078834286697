import {
  Datagrid,
  DeleteButton,
  FormTab,
  Pagination,
  ReferenceManyField,
  EditButton,
  TextField,
  TextInput,
  useCreate, useRefresh, useNotify
} from 'react-admin'
import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { useFormState } from 'react-final-form'
import TimestampsFields from '../../../elements/TimestampsFields'

const DevicesTab = ({ ...props }) => {
  const state = useFormState()
  const notify = useNotify()
  const refresh = useRefresh()
  const [create] = useCreate(
    'devices',
    {
      name: state.values.deviceName,
      propertyId: state.values.id
    }
  )

  return (
    <FormTab {...props} label='devices'>
      <TextInput
        source='deviceName'
        label='Create device'
      />
      <Button
        onClick={() => {
          create({}, {
            onSuccess: () => {
              refresh()
            },
            onFailure: (error) => {
              notify(error.message, 'error')
            }
          })
        }}
        disabled={!state.values.deviceName}
        color='primary'
        variant='contained'
      >
        Create
      </Button>

      <Typography variant='body1' style={{ margin: '50px 0 20px' }}>
        Connected devices:
      </Typography>

      <ReferenceManyField
        addLabel={false}
        fullWidth
        reference='devices'
        target='propertyId'
        sort={{
          field: 'createdAt',
          order: 'DESC'
        }}
        pagination={<Pagination />}
      >
        <Datagrid>
          <TextField source='name' />
          <TextField source='licencesCode' />
          {TimestampsFields}
          <EditButton />
          <DeleteButton redirect={false} />
        </Datagrid>
      </ReferenceManyField>
    </FormTab>
  )
}

export default DevicesTab
