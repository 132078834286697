import React from 'react'
import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  FormTab,
  List,
  required,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'
import TimestampsFields from '../../elements/TimestampsFields'
import Toolbar from '../../elements/Layout/Toolbar'

export const Filters = (props) => (
  <Filter {...props}>
    <TextInput
      label='Email'
      source='email.$regex'
      alwaysOn
    />
    {DateFilters}
  </Filter>
)

export const SubAdminCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source='email'
        label='Email'
        type='email'
        validate={required()}
      />
      <TextInput
        source='phone'
        label='Phone'
        type='text'
        validate={required()}
      />
      <TextInput
        source='password'
        label='Password'
        type='password'
        validate={required()}
      />
    </SimpleForm>
  </Create>
)

export const SubAdminEdit = (props) => (
  <Edit {...props}>
    <TabbedForm
      redirect={false}
      toolbar={<Toolbar />}
    >
      <FormTab label='summary'>
        <TextField
          source='_id'
          label='Id'
        />
        <TextInput
          source='email'
          label='Email'
          type='text'
          validate={required()}
        />
        <TextInput
          source='phone'
          label='Phone'
          type='text'
          validate={required()}
        />
        <TextInput
          source='password'
          label='Password'
          type='password'
          validate={required()}
        />
        {TimestampsFields}
      </FormTab>
    </TabbedForm>
  </Edit>
)

export const SubAdminList = (props) => (
  <List
    {...props}
    filters={<Filters />}
  >
    <Datagrid>
      <TextField
        source='email'
        label='email'
      />
      {TimestampsFields}
      <EditButton />
    </Datagrid>
  </List>
)
