import React from 'react'
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput
} from 'react-admin'

import TimestampsFields from '../../elements/TimestampsFields'
import DateFilters from '../../elements/DateFilters'

export const Filters = (props) => (
  <Filter {...props}>
    <ReferenceInput
      source='subAdminId'
      label='System Admin'
      reference='subAdmins'
      alwaysOn
    >
      <SelectInput optionText='email' />
    </ReferenceInput>
    <TextInput
      label='Message'
      source='message.$regex'
      alwaysOn
    />
    {DateFilters}
  </Filter>
)


const SystemLogsList = (props) => (
  <List
    {...props}
    filters={<Filters />}
  >
    <Datagrid>
      <ReferenceField
        source='subAdminId'
        label='System Admin'
        reference='subAdmins'
      >
        <TextField source='email' />
      </ReferenceField>
      <TextField
        source='message'
        label='Message'
      />
      {TimestampsFields}
      <EditButton />
    </Datagrid>
  </List>
)

export default SystemLogsList
