import React, { useEffect } from 'react'
import { required, SaveButton, SimpleForm, TextField, TextInput, Toolbar, useNotify } from 'react-admin'
import AccessFields from '../AccessFields'
import Change from '../../../elements/Change'
import TimestampsFields from '../../../elements/TimestampsFields'
import { createMuiTheme } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { useMutation, useRedirect } from 'ra-core'
import debounce from 'lodash/debounce'
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles(
  theme => ({
    flex: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  })
)

const redTheme = createMuiTheme({ palette: { primary: red } })

const EditToolbar = props => {
  const classes = useStyles()
  const notify = useNotify()
  const redirect = useRedirect()
  const [archive, { error }] = useMutation({
    type: 'update',
    resource: props.resource,
    payload: {
      id: props.record.id,
      data: {
        status: 'archived'
      }
    }
  }, {
    onSuccess: () => redirect('/qrAccesses')
  })

  const archiveAccess = debounce(archive, 300)

  useEffect(() => {
    if (error?.message) {
      console.error(error)
      notify(error.message, 'warning')
    }
  }, [error, notify])

  return (
    <Toolbar {...props} className={classes.flex}>
      <SaveButton />
      <MuiThemeProvider theme={redTheme}>
        <Button startIcon={<DeleteIcon />} color='primary' variant='text' redirect={false} onClick={archiveAccess}>
          Archive
        </Button>
      </MuiThemeProvider>
    </Toolbar>
  )
}

const QrAccessChange = (props) => (
  <Change {...props}>
    <SimpleForm
      toolbar={props.id && <EditToolbar />}
      redirect={!props.id ? 'edit' : false}
    >
      {props.id && (
        <TextField
          source='_id'
          label='Id'
        />
      )}
      <AccessFields />
      <TextInput
        source='guestName'
        validate={required()}
      />
      <TextInput
        source='guestPhone'
        validate={required()}
      />
      {props.id && TimestampsFields}
    </SimpleForm>
  </Change>
)

export default QrAccessChange
