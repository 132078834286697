import React from 'react'
import {
  useInput,
  useTranslate
} from 'react-admin'

import {
  KeyboardTimePicker
} from '@material-ui/pickers'

const TimePicker = (props) => {
  const translate = useTranslate()
  const {
    input: { name, value, onChange },
    meta: { touched, error },
    isRequired
  } = useInput(props)
  const handleTimeChange = (date, newTime) => {
    onChange(newTime)
  }
  const hasError = !!(touched && error)
  const helperText = touched && error ? translate(error) : false

  return (
    <KeyboardTimePicker
      name={name}
      margin='dense'
      label={props.label}
      mask='__:__ _M'
      value={value === '' ? null : undefined}
      inputValue={value}
      onChange={handleTimeChange}
      KeyboardButtonProps={{
        'aria-label': 'change time'
      }}
      error={hasError}
      helperText={helperText}
      required={isRequired}
    />
  )
}

export default TimePicker
