import React from 'react'
import { Admin, Resource } from 'react-admin'
import { authClient, restClient } from 'ra-data-feathers'
import feathersClient from './client/feathersClient'
import i18nProvider from './i18nProvider'

import Person from '@material-ui/icons/Person'
import SupervisorAccount from '@material-ui/icons/SupervisorAccount'
import AccountCircle from '@material-ui/icons/AccountCircle'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import ApartmentIcon from '@material-ui/icons/Apartment'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import DevicesIcon from '@material-ui/icons/Devices'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'
import CropFreeIcon from '@material-ui/icons/CropFree'
import EventIcon from '@material-ui/icons/Event'
import EmailIcon from '@material-ui/icons/Email'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import SmsIcon from '@material-ui/icons/Sms'
import AddAlertIcon from '@material-ui/icons/AddAlert'
import AirlineSeatFlatIcon from '@material-ui/icons/AirlineSeatFlat'
import SubscriptionsIcon from '@material-ui/icons/Subscriptions'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'

import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import { UserCreate, UserEdit, UserList } from './models/users'
import { AdminCreate, AdminEdit, AdminList } from './models/admins'
import { SubAdminCreate, SubAdminEdit, SubAdminList } from './models/subAdmins'
import { MobileUserCreate, MobileUserEdit, MobileUserList } from './models/mobileUsers'
import PropertyList from './models/properties/List'
import { PropertyAdminCreate, PropertyAdminEdit } from './models/propertyAdmins'
import { PropertyManagerCreate, PropertyManagerEdit, PropertyManagerList } from './models/propertyManagers'
import { DeviceChange, DeviceList } from './models/devices'
import { AppAccessChange } from './models/accesses/appAccesses'
import AppAccessList from './models/accesses/appAccesses/List'
import { EventCreate, EventEdit, EventList } from './models/events'
import { EmailCreate, EmailEdit, EmailList } from './models/emails'
import { MobileUsersFlatsCreate, MobileUsersFlatsEdit } from './models/mobileUsersFlats'
import { SMSCreate, SMSEdit, SMSList } from './models/sms'
import { PropertyAdminsPropertiesCreate, PropertyAdminsPropertiesShow } from './models/propertyAdminsProperties'
import ReportCaseChange from './models/reportCases'
import { DeliveryAdminCreate, DeliveryAdminEdit, DeliveryAdminList } from './models/deliveryAdmins'
import { DeliveryCompanyChange, DeliveryCompanyList } from './models/deliveryCompanies'
import { DeliveryCompanyDelivererChange, DeliveryCompanyDelivererList } from './models/deliveryCompanyDeliverer'
import { PropertyManagersPropertiesCreate, PropertyManagersPropertiesShow } from './models/propertyManagersProperties'
import { PushNotificationsCreate, PushNotificationsEdit, PushNotificationsList } from './models/pushNotifications'
import Layout from './elements/Layout'
import PropertyChange from './models/properties'
import PropertyAdminList from './models/propertyAdmins/List'
import QrAccessList from './models/accesses/qrAccesses/List'
import QrAccessChange from './models/accesses/qrAccesses'
import FlatList from './models/flats/List'
import FlatChange from './models/flats'
import MobileUsersFlatsList from './models/mobileUsersFlats/List'
import PropertyNotificationsList from './models/propertyNotification/List'
import { PropertyNotificationsChange } from './models/propertyNotification'
import ReportCaseList from './models/reportCases/List'
import { SubscriptionChange } from './models/subscriptions'
import SubscriptionList from './models/subscriptions/List'
import PropertyManagersPropertiesList from './models/propertyManagersProperties/List'
import PropertyAdminPropertiesList from './models/propertyAdminsProperties/List'
import { Route } from 'react-router-dom'
import VerifyToken from './pages/VerifyToken'
import LoginPage from './pages/Login'
import SystemLogsList from './models/systemLogs'
import CommunitiesList from './models/communities/List'
import CommunityChange from './models/communities'
import { CommunityAdminEdit } from './models/communitiesAdmins/Edit'
import LicensesList from './models/licenses/List'
import { LicensesEdit } from './models/licenses'

const restClientOptions = {
  id: '_id', // In this example, the database uses '_id' rather than 'id'
  usePatch: true // Use PATCH instead of PUT for updates
}

const authClientOptions = {
  storageKey: 'feathers-jwt',
  authenticate: { // Options included in calls to Feathers client.authenticate
    strategy: 'admin' // The authentication strategy Feathers should use
  },
  passwordField: 'password', // The key used to provide the password to Feathers client.authenticate
  usernameField: 'email', // Our example database might use 'username' rather than 'email'
  redirectTo: '/login' // Our example login form might be at '/login', redirect here if AUTH_CHECK fails
}

const customRoutes = [
  <Route key='verifyToken' exact path='/verifyToken' component={VerifyToken} noLayout />,
]

const App = () => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Admin
        dataProvider={restClient(feathersClient, restClientOptions)}
        authProvider={authClient(feathersClient, authClientOptions)}
        locale='en'
        i18nProvider={i18nProvider}
        layout={Layout}
        loginPage={LoginPage}
        customRoutes={customRoutes}
      >
        <Resource
          name='properties'
          icon={LocationCityIcon}
          list={PropertyList}
          create={PropertyChange}
          edit={PropertyChange}
        />
        <Resource
          name='communities'
          icon={ApartmentIcon}
          list={CommunitiesList}
          create={CommunityChange}
          edit={CommunityChange}
        />
        <Resource
          name='communityAdmins'
          edit={CommunityAdminEdit}
        />
        <Resource
          name='mobileUsers'
          icon={AccountCircle}
          list={MobileUserList}
          create={MobileUserCreate}
          edit={MobileUserEdit}
        />
        <Resource
          name='propertyManagers'
          icon={BusinessCenterIcon}
          list={PropertyManagerList}
          create={PropertyManagerCreate}
          edit={PropertyManagerEdit}
        />
        <Resource
          name='propertyAdmins'
          icon={SupervisedUserCircleIcon}
          list={PropertyAdminList}
          create={PropertyAdminCreate}
          edit={PropertyAdminEdit}
        />
        <Resource
          name='deliveryAdmins'
          icon={SupervisedUserCircleIcon}
          list={DeliveryAdminList}
          create={DeliveryAdminCreate}
          edit={DeliveryAdminEdit}
        />
        <Resource
          name='admins'
          icon={SupervisorAccount}
          list={AdminList}
          create={AdminCreate}
          edit={AdminEdit}
          options={{
            label: 'Super Admins'
          }}
        />
        <Resource
          name='subAdmins'
          icon={SupervisorAccount}
          list={SubAdminList}
          create={SubAdminCreate}
          edit={SubAdminEdit}
          options={{
            label: 'System Admins'
          }}
        />
        <Resource
          name='users'
          icon={Person}
          list={UserList}
          create={UserCreate}
          edit={UserEdit}
        />
        <Resource
          name='devices'
          icon={DevicesIcon}
          list={DeviceList}
          create={DeviceChange}
          edit={DeviceChange}
        />
        <Resource
          name='flats'
          icon={MeetingRoomIcon}
          list={FlatList}
          create={FlatChange}
          edit={FlatChange}
        />
        <Resource
          name='events'
          icon={EventIcon}
          list={EventList}
          create={EventCreate}
          edit={EventEdit}
        />
        <Resource
          name='propertyAdminsProperties'
          icon={SupervisedUserCircleIcon}
          list={PropertyAdminPropertiesList}
          create={PropertyAdminsPropertiesCreate}
          show={PropertyAdminsPropertiesShow}
        />
        <Resource
          name='propertyManagersProperties'
          icon={SupervisedUserCircleIcon}
          list={PropertyManagersPropertiesList}
          create={PropertyManagersPropertiesCreate}
          show={PropertyManagersPropertiesShow}
        />
        <Resource
          name='propertyNotifications'
          icon={NotificationsActiveIcon}
          list={PropertyNotificationsList}
          create={PropertyNotificationsChange}
        />
        <Resource
          name='mobileUsersFlats'
          icon={AirlineSeatFlatIcon}
          list={MobileUsersFlatsList}
          create={MobileUsersFlatsCreate}
          edit={MobileUsersFlatsEdit}
        />
        <Resource
          name='qrAccesses'
          icon={CropFreeIcon}
          list={QrAccessList}
          create={QrAccessChange}
          edit={QrAccessChange}
        />
        <Resource
          name='appAccesses'
          icon={CropFreeIcon}
          list={AppAccessList}
          create={AppAccessChange}
          edit={AppAccessChange}
        />
        <Resource
          name='emails'
          icon={EmailIcon}
          list={EmailList}
          create={EmailCreate}
          edit={EmailEdit}
        />
        <Resource
          name='sms'
          icon={SmsIcon}
          list={SMSList}
          create={SMSCreate}
          edit={SMSEdit}
        />
        <Resource
          name='pushNotifications'
          icon={AddAlertIcon}
          list={PushNotificationsList}
          create={PushNotificationsCreate}
          edit={PushNotificationsEdit}
        />
        <Resource
          name='reportCases'
          icon={EventIcon}
          list={ReportCaseList}
          create={ReportCaseChange}
          edit={ReportCaseChange}
        />
        <Resource
          name='subscriptions'
          icon={SubscriptionsIcon}
          list={SubscriptionList}
          create={SubscriptionChange}
          edit={SubscriptionChange}

        />
        <Resource
          name='deliveryCompanies'
          icon={LocalShippingIcon}
          list={DeliveryCompanyList}
          create={DeliveryCompanyChange}
          edit={DeliveryCompanyChange}
        />
        <Resource
          name='deliveryCompanyDeliverer'
          icon={LocalShippingIcon}
          list={DeliveryCompanyDelivererList}
          create={DeliveryCompanyDelivererChange}
          edit={DeliveryCompanyDelivererChange}
        />
        <Resource
          name='systemLogs'
          icon={EventIcon}
          list={SystemLogsList}
        />
        <Resource
          name='licenses'
          icon={EventIcon}
          list={LicensesList}
          create={LicensesEdit}
          edit={LicensesEdit}
          options={{
            label: 'On-premise Licenses'
          }}
        />
      </Admin>
    </MuiPickersUtilsProvider>
  )
}

export default App
