import get from 'lodash/get'

const _config = [
  {
    field: 'createdAfter',
    mongoFiled: 'createdAt',
    parse: v => ({
      $gt: v
    }),
    merge: true
  },
  {
    field: 'createdBefore',
    mongoFiled: 'createdAt',
    parse: v => ({
      $lt: v
    }),
    merge: true
  },
  {
    field: 'updatedAfter',
    mongoFiled: 'updatedAt',
    parse: v => ({
      $gt: v
    }),
    merge: true
  },
  {
    field: 'updatedBefore',
    mongoFiled: 'updatedAt',
    parse: v => ({
      $lt: v
    }),
    merge: true
  },
  {
    field: '$regex',
    mongoFiled: '$regex',
    parse: v => v,
    deep: true
  },
  {
    field: '$options',
    mongoFiled: '$options',
    parse: v => v,
    deep: true
  }
]

export default function mapQuery (config = _config) {
  return async context => {
    if (context.params.query) {
      config.forEach(
        ({ field, mongoFiled, parse, ...option }) => {
          if (get(context.params.query, field)) {
            if (option.merge) {
              context.params.query[mongoFiled] = {
                ...context.params.query[mongoFiled],
                ...parse(get(context.params.query, field))
              }
            } else {
              context.params.query[mongoFiled] = parse(get(context.params.query, field))
            }

            delete get(context.params.query, field)
          } else if (option.deep) {
            const key = Object.keys(context.params.query).find(key => key.includes(field))
            if (key) {
              const source = key.split('.')[0]
              if (!context.params.query[source]) {
                context.params.query[source] = {}
              }
              context.params.query[source][field] = context.params.query[key]

              delete context.params.query[key]
            }
          }
        }
      )
    }
  }
}
