import { Datagrid, DateField, EditButton, Filter, List, SelectInput, TextField, TextInput } from 'react-admin'
import React from 'react'
import DateFilters from '../../elements/DateFilters'
import createChoices from '../../utils/createChoices'
import { ESecurityLevel } from './enums'

export const Filters = (props) => (
  <Filter {...props}>
    <TextInput
      label='Name'
      source='name.$regex'
      alwaysOn
    />
    <TextInput
      label='Reference Number'
      source='refNumber.$regex'
    />
    <TextInput
      label='Address'
      source='address.$regex'
    />
    <SelectInput
      choices={createChoices(ESecurityLevel)}
      source='securityLevel'
    />
    {DateFilters}
  </Filter>
)

const PropertyList = (props) => (
  <List
    {...props}
    filters={<Filters />}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField
        source='name'
        label='name'
      />
      <TextField
        source='refNumber'
        label='Reference Number'
      />
      <TextField
        source='address'
        label='Address'
      />
      <DateField source='createdAt' showTime />
      <DateField source='updatedAt' showTime />
      <EditButton />
    </Datagrid>
  </List>
)

export default PropertyList
