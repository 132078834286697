import { SaveButton, Toolbar as RAToolbar } from 'react-admin'
import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}))

const Toolbar = props => {
  const classes = useStyles()
  const location = useLocation()
  const disableSave = useMemo(() => {
    const pageIndex = location.pathname.split('/').pop()
    return !isNaN(Number(pageIndex))
  }, [location.pathname])

  return (
    <RAToolbar {...props} className={classes.flex}>
      {!disableSave ? <SaveButton /> : <div />}
    </RAToolbar>
  )
}

export default Toolbar
