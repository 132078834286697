import decodeJwt from 'jwt-decode'

const useUser = () => {
  const storedProfile = window.localStorage.getItem('feathers-jwt')
  const id = decodeJwt(storedProfile).sub
  return {
    id
  }
}

export default useUser
