export const EMobileUsersFlatsStatus = {
  PENDING_INVITATION: 0,
  PENDING_APPROVAL: 1,
  ACCEPTED: 2,
  DECLINED: 3
}

export const statuses = [
  {
    id: EMobileUsersFlatsStatus.PENDING_INVITATION,
    name: 'Pending invitation'
  },
  {
    id: EMobileUsersFlatsStatus.PENDING_APPROVAL,
    name: 'Pending approval'
  },
  {
    id: EMobileUsersFlatsStatus.DECLINED,
    name: 'Declined'
  },
  {
    id: EMobileUsersFlatsStatus.ACCEPTED,
    name: 'Accepted'
  }
]
