import React from 'react'
import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  FormTab,
  List,
  required,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'
import { validatePhone } from '../../validators'
import TimestampsFields from '../../elements/TimestampsFields'

export const Filters = (props) => (
  <Filter {...props}>
    <TextInput
      label='Search Phone number'
      source='phoneNumber.$regex'
      alwaysOn
    />
    {DateFilters}
  </Filter>
)

export const SMSCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source='phoneNumber'
        label='Phone number'
        type='text'
        validate={[required(), validatePhone()]}
      />
      <TextInput
        source='subject'
      />
      <TextInput
        source='text'
        label='Text'
        type='text'
        validate={required()}
      />
    </SimpleForm>
  </Create>
)

export const SMSEdit = (props) => (
  <Edit {...props}>
    <TabbedForm
      redirect={false}
      toolbar={false}
    >
      <FormTab label='summary'>
        <TextField
          source='_id'
          label='Id'
        />
        <TextField
          source='phoneNumber'
          label='Phone number'
          type='text'
          validate={[required(), validatePhone()]}
        />
        <TextField
          source='subject'
        />
        <TextField
          source='text'
          label='Text'
        />
        {TimestampsFields}
      </FormTab>
    </TabbedForm>
  </Edit>
)

export const SMSList = (props) => (
  <List
    {...props}
    filters={<Filters />}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField
        source='phoneNumber'
        type='text'
      />
      <TextField
        source='subject'
      />
      <TextField
        source='text'
        type='text'
      />
      {TimestampsFields}
      <EditButton />
    </Datagrid>
  </List>
)
