import { Create, Edit } from 'react-admin'
import React from 'react'

const Change = (props) => {
  if (props.id) {
    return <Edit {...props} />
  }
  return <Create {...props} />
}

export default Change
