import React from 'react'
import {
  TextInput,
  TextField,
  TabbedForm,
  FormTab,
  ReferenceInput,
  SelectInput,
  required,
  AutocompleteInput
} from 'react-admin'

import Toolbar from '../../elements/Layout/Toolbar'
import Change from '../../elements/Change'
import toRegex from '../../utils/toRegex'
import { statuses } from './enums'
import TimestampsFields from '../../elements/TimestampsFields'

const ReportCaseChange = (props) => (
  <Change {...props}>
    <TabbedForm
      toolbar={props.id && <Toolbar />}
      redirect={!props.id && 'edit'}
    >
      <FormTab label='summary'>
        {props.id && (
          <TextField
            source='_id'
            label='Id'
          />
        )}

        <TextInput
          label='Guard Name'
          source='guardName'
        />
        <ReferenceInput
          source='guestId'
          label='Guest'
          reference='mobileUsers'
          sort={{ field: 'email', order: 'ASC' }}
          filterToQuery={(v) => toRegex('email')(v)}
        >
          <AutocompleteInput optionText='email' emptyText='clear search' />
        </ReferenceInput>
        <TextInput
          label='Guest Emirates ID'
          source='guestEmiratesId'
        />
        <TextInput
          label='Guest Name'
          source='guestName'
        />
        <TextInput
          label='Guest Mobile Number'
          source='guestMobileNumber'
        />
        <ReferenceInput
          source='hostId'
          label='Host'
          reference='mobileUsers'
          sort={{ field: 'email', order: 'ASC' }}
          filterToQuery={(v) => toRegex('email')(v)}
          validate={required()}
        >
          <AutocompleteInput optionText='email' />
        </ReferenceInput>
        <ReferenceInput
          source='flatId'
          label='Flat'
          reference='flats'
          sort={{ field: 'flatNumber', order: 'ASC' }}
          filterToQuery={(v) => toRegex('flatNumber')(v)}
          validate={required()}
        >
          <AutocompleteInput optionText='flatNumber' />
        </ReferenceInput>
        <ReferenceInput
          source='deviceId'
          label='Device'
          reference='devices'
          sort={{ field: 'name', order: 'ASC' }}
          filterToQuery={(v) => toRegex('name')(v)}
          validate={required()}
        >
          <AutocompleteInput optionText='name' />
        </ReferenceInput>
        <TextInput
          label='Notes'
          source='notes'
        />
        <SelectInput
          source='status'
          choices={statuses}
          validate={required()}
        />
        {props.id && TimestampsFields}
      </FormTab>
    </TabbedForm>
  </Change>
)

export default ReportCaseChange
