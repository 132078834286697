import { Datagrid, EditButton, Filter, List, ReferenceField, SelectField, TextField, TextInput } from 'react-admin'
import React from 'react'
import DateFilters from '../../elements/DateFilters'
import TimestampsFields from '../../elements/TimestampsFields'
import { statuses } from './enums'

export const Filters = (props) => (
  <Filter {...props}>
    <TextInput
      label='ReportCase number'
      source='flatNumber.$regex'
      alwaysOn
    />
    {DateFilters}
  </Filter>
)

const ReportCaseList = (props) => (
  <List
    {...props}
    filters={<Filters />}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField
        source='_id'
        label='Id'
      />
      <TextField
        label='Guard Name'
        source='guardName'
      />
      <ReferenceField
        source='guestId'
        label='Guest'
        reference='mobileUsers'
      >
        <TextField source='name' />
      </ReferenceField>
      <TextField
        label='Guest Name'
        source='guestName'
      />
      <ReferenceField
        source='hostId'
        label='Host'
        reference='mobileUsers'
      >
        <TextField source='name' />
      </ReferenceField>
      <ReferenceField
        source='flatId'
        label='Flat'
        reference='flats'
      >
        <TextField source='flatNumber' />
      </ReferenceField>
      <TextField
        label='Notes'
        source='notes'
      />
      <SelectField
        source='status'
        choices={statuses}
      />
      {TimestampsFields}
      <EditButton />
    </Datagrid>
  </List>
)

export default ReportCaseList
