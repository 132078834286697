import React from 'react'
import { AutocompleteInput, FormTab, ReferenceInput, required, TabbedForm, TextField, TextInput } from 'react-admin'
import TimestampsFields from '../../elements/TimestampsFields'
import Toolbar from '../../elements/Layout/Toolbar'
import Change from '../../elements/Change'
import toRegex from '../../utils/toRegex'

export const PropertyNotificationsChange = (props) => (
  <Change {...props}>
    <TabbedForm
      toolbar={props.id && <Toolbar hideDelete />}
      redirect={!props.id && 'edit'}
    >
      <FormTab label='summary'>
        {props.id && (
          <TextField
            source='_id'
            label='Id'
          />
        )}
        <TextInput
          source='title'
          label='Title'
          type='text'
          fullWidth
          validate={required()}
        />
        <TextInput
          source='text'
          label='Message'
          fullWidth
          multiline
          validate={required()}
        />
        <ReferenceInput
          label='Property'
          source='propertyId'
          reference='properties'
          sort={{ field: 'name', order: 'ASC' }}
          filterToQuery={(v) => toRegex('name')(v)}
          validate={required()}
        >
          <AutocompleteInput optionText='name' emptyText='clear search' />
        </ReferenceInput>
        {props.id && TimestampsFields}
      </FormTab>
    </TabbedForm>
  </Change>
)
