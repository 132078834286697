import { Datagrid, EditButton, Filter, List, NumberField, ReferenceField, TextField } from 'react-admin'
import TimestampsFields from '../../elements/TimestampsFields'
import React from 'react'
import DateFilters from '../../elements/DateFilters'

export const Filters = (props) => (
  <Filter {...props}>
    {DateFilters}
  </Filter>
)

const SubscriptionList = (props) => (
  <List
    {...props}
    filters={<Filters />}
  >
    <Datagrid>
      <ReferenceField
        label='Property'
        source='propertyId'
        reference='properties'
      >
        <TextField source='name' />
      </ReferenceField>
      <NumberField
        source='maxUsers'
        label='Max users'
      />
      <NumberField
        source='activeUsers'
        label='Active users'
      />
      {TimestampsFields}
      <EditButton />
    </Datagrid>
  </List>
)
export default SubscriptionList
