import React from 'react'
import {
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  EditButton,
  Filter,
  FormDataConsumer,
  FormTab,
  List,
  Pagination,
  required,
  SelectInput,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
} from 'react-admin'

import { makeStyles } from '@material-ui/core/styles'

import cond from 'lodash/cond'
import matches from 'lodash/matches'
import stubTrue from 'lodash/stubTrue'
import constant from 'lodash/constant'

import { EVENT_TYPE, eventTypeChoices } from '../../constants'
import DateFilters from '../../elements/DateFilters'
import TimestampsFields from '../../elements/TimestampsFields'

import {
  DeliveryEventsInputsCreate,
  DeliveryEventsInputsEdit,
  EnterEventsInputsCreate,
  EnterEventsInputsEdit,
  EventsInputsCreate,
  EventsInputsEdit,
  LeaveEventsInputsCreate,
  LeaveEventsInputsEdit,
  ReportEventsInputsCreate,
  ReportEventsInputsEdit
} from './inputs'

const useStyles = makeStyles(
  theme => ({
    additionalFields: {
      display: 'flex',
      flexDirection: 'column',
      width: theme.spacing(32)
    }
  })
)

export const Filters = (props) => (
  <Filter {...props}>
    <TextInput
      label='Message'
      source='message.$regex'
      alwaysOn
    />
    <DateTimeInput
      source='date.$gt'
      label='From'
      alwaysOn
    />
    <DateTimeInput
      source='date.$lt'
      label='To'
      alwaysOn
    />
    <SelectInput
      source='type'
      choices={eventTypeChoices}
      alwaysOn
    />
    {DateFilters}
  </Filter>
)

export const EventCreate = (props) => {
  const classes = useStyles()

  const getInputsCreateByType = cond([
    [matches({ type: EVENT_TYPE.ENTER_EVENTS }), constant(EnterEventsInputsCreate)],
    [matches({ type: EVENT_TYPE.LEAVE_EVENTS }), constant(LeaveEventsInputsCreate)],
    [matches({ type: EVENT_TYPE.REPORT_EVENTS }), constant(ReportEventsInputsCreate)],
    [matches({ type: EVENT_TYPE.DELIVERY_EVENTS }), constant(DeliveryEventsInputsCreate)],
    [stubTrue, constant(null)]
  ])

  return (
    <Create {...props}>
      <SimpleForm>
        <SelectInput
          source='type'
          label='Type'
          choices={eventTypeChoices}
          validate={required()}
        />
        {EventsInputsCreate}
        <FormDataConsumer formClassName={classes.additionalFields}>
          {({ formData }) => getInputsCreateByType(formData)}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}

export const EventEdit = (props) => {
  const classes = useStyles()

  const getInputsEditByType = cond([
    [matches({ type: EVENT_TYPE.ENTER_EVENTS }), constant(EnterEventsInputsEdit)],
    [matches({ type: EVENT_TYPE.LEAVE_EVENTS }), constant(LeaveEventsInputsEdit)],
    [matches({ type: EVENT_TYPE.REPORT_EVENTS }), constant(ReportEventsInputsEdit)],
    [matches({ type: EVENT_TYPE.DELIVERY_EVENTS }), constant(DeliveryEventsInputsEdit)],
    [stubTrue, constant(null)]
  ])

  return (
    <Edit {...props}>
      <TabbedForm
        redirect={false}
        toolbar={false}
      >
        <FormTab label='summary'>
          <TextField
            source='_id'
            label='Id'
          />
          <TextField
            source='type'
            label='Type'
          />
          {EventsInputsEdit}
          <FormDataConsumer formClassName={classes.additionalFields}>
            {({ formData }) => getInputsEditByType(formData)}
          </FormDataConsumer>
          {TimestampsFields}
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

const EventsPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />

export const EventList = (props) => (
  <List
    {...props}
    filters={<Filters />}
    pagination={<EventsPagination />}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField
        source='message'
        label='Message'
      />
      <DateField
        source='date'
        label='Date'
        showTime
      />
      <TextField
        source='type'
        label='Type'
      />
      <DateField source='createdAt' showTime />
      <DateField source='updatedAt' showTime />
      <EditButton />
    </Datagrid>
  </List>
)
