import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, CardContent, CircularProgress } from '@material-ui/core'
import { Form } from 'react-final-form'
import { styled } from '@material-ui/styles'
import { required, TextInput, useLogin, useNotify, useSafeSetState, useTranslate, } from 'react-admin'
import { debounce } from 'lodash'

const LoginForm = (props) => {
  const { className } = props
  const [loading, setLoading] = useSafeSetState(false)
  const login = useLogin()
  const notify = useNotify()
  const translate = useTranslate()
  const history = useHistory()
  const submit = (values) => {
    setLoading(true)
    login({
      username: values.username,
      password: values.password
    })
      .catch((error) => {
        if (error.message.includes('OTP')) {
          history.push({
            pathname: '/verifyToken',
            state: {
              username: values.username,
              password: values.password
            }
          })
        } else {
          notify('messages.login.invalidEmailOrPassword')
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <Form
      onSubmit={submit}
    >
      {props => (
        <StyledForm
          onSubmit={(e) => e.preventDefault()}
          className={className}
        >
          <CardContent className={LoginFormClasses.content}>
            <TextInput
              source='username'
              variant='standard'
              autoFocus
              id='username'
              margin='small'
              name='username'
              label={translate('ra.auth.username')}
              validate={required('messages.login.email.required')}
              fullWidth
            />
            <TextInput
              source='password'
              variant='standard'
              id='password'
              margin='small'
              name='password'
              label={translate('ra.auth.password')}
              type="password"
              autoComplete="current-password"
              validate={required('messages.login.password.required')}
              fullWidth
            />

            <Button
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
              className={LoginFormClasses.button}
              onClick={debounce(props.handleSubmit, 300)}
              disabled={props.submitting || props.invalid || loading}
            >
              {loading ? (
                <CircularProgress
                  className={LoginFormClasses.icon}
                  size={19}
                  thickness={3}
                />
              ) : (
                translate('ra.auth.sign_in')
              )}
            </Button>
          </CardContent>
        </StyledForm>
      )}
    </Form>
  )
}


const PREFIX = 'RaLoginForm'

export const LoginFormClasses = {
  content: `${PREFIX}-content`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
}

const StyledForm = styled('form', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(() => ({
  [`& .${LoginFormClasses.content}`]: {
    width: 300,
  },
  [`& .${LoginFormClasses.button}`]: {
    marginTop: 16,
  },
  [`& .${LoginFormClasses.icon}`]: {
    margin: 0.8 * 3,
  },
}))

export default LoginForm
