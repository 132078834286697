import {
  Datagrid,
  DateField,
  DeleteButton,
  downloadCSV,
  Filter,
  List,
  ReferenceField,
  ShowButton,
  TextField
} from 'react-admin'
import * as jsonExport from 'jsonexport/dist'
import React from 'react'
import DateFilters from '../../elements/DateFilters'
import { format } from 'date-fns'

const exporter = (propertyManagersProperties, fetchRelatedRecords) => {
  let properties = {}
  let propertyManagers = {}
  const numbersOfRequests = Math.ceil(propertyManagersProperties.length / 100)
  const promises = []
  for (let i = 0; i < numbersOfRequests; i++) {
    promises.push(new Promise((resolve) => {
      fetchRelatedRecords(propertyManagersProperties.slice(i * 100, (i + 1) * 100), 'propertyId', 'properties')
        .then((data) => {
          properties = { ...properties, ...data }
          resolve()
        })
    }))
    promises.push(new Promise((resolve) => {
      fetchRelatedRecords(propertyManagersProperties.slice(i * 100, (i + 1) * 100), 'propertyManagerId', 'propertyManagers')
        .then((data) => {
          propertyManagers = { ...propertyManagers, ...data }
          resolve()
        })
    }))
  }

  Promise.all(promises).then(() => {
    const propertyManagersPropertiesForExport = propertyManagersProperties.map(propertyManagersProperty => {
      const { propertyId, propertyManagerId } = propertyManagersProperty
      propertyManagersProperty.propertyManager = propertyManagers[propertyManagerId]?.name
      propertyManagersProperty.property = properties[propertyId]?.name
      delete propertyManagersProperty._id
      delete propertyManagersProperty.__v
      delete propertyManagersProperty.propertyId
      delete propertyManagersProperty.propertyManagerId
      return propertyManagersProperty
    });
    jsonExport(propertyManagersPropertiesForExport, {
      headers: ['id', 'propertyManager', 'property', 'createdAt', 'updatedAt']
    }, (err, csv) => {
      downloadCSV(csv, `propertyManagersProperties-${format(new Date(), 'dd-MM-yyyy')}`)
    })
  })
}

export const Filters = (props) => (
  <Filter {...props}>
    {DateFilters}
  </Filter>
)

const PropertyManagersPropertiesList = (props) => (
  <List
    {...props}
    exporter={exporter}
    filters={<Filters />}
  >
    <Datagrid>
      <ReferenceField label='Property Manager' source='propertyManagerId' reference='propertyManagers'>
        <TextField source='email' />
      </ReferenceField>
      <ReferenceField label='Property' source='propertyId' reference='properties'>
        <TextField source='name' />
      </ReferenceField>
      <DateField source='createdAt' showTime />
      <DateField source='updatedAt' showTime />
      <ShowButton />
      <DeleteButton />
    </Datagrid>
  </List>
)

export default PropertyManagersPropertiesList
