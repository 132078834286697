import React, { useState } from 'react'
import {
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Filter,
  FormTab,
  ImageField,
  List,
  required,
  SelectField,
  SelectInput,
  ShowButton,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput
} from 'react-admin'
import Button from '@material-ui/core/Button'
import DateFilters from '../../elements/DateFilters'
import CloudinaryField from '../../elements/CloudinaryField'
import Toolbar from '../../elements/Layout/Toolbar'
import { validatePhone } from '../../validators'
import { mobileUsersStatuses } from './enums'

export const Filters = (props) => (
  <Filter {...props}>
    <TextInput
      label='Email'
      source='email.$regex'
      alwaysOn
    />
    <TextInput
      label='Phone'
      source='phone.$regex'
      alwaysOn
    />
    <TextInput
      label='Name'
      source='name.$regex'
      alwaysOn
    />
    <SelectInput
      source='status'
      label='Status'
      choices={mobileUsersStatuses}
      alwaysOn
    />
    {DateFilters}
  </Filter>
)

export const MobileUserCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source='email'
        label='Email'
        validate={required()}
      />
      <TextInput
        source='phone'
        label='Phone'
        type='phone'
        validate={[required(), validatePhone()]}
      />
      <TextInput
        source='name'
        label='Name'
      />
      <CloudinaryField source='photo' label='User photo' />
      <TextInput
        source='password'
        label='Password'
        type='password'
        validate={required()}
      />
      <SelectInput
        source='status'
        label='Status'
        choices={mobileUsersStatuses}
        validate={required()}
      />
    </SimpleForm>
  </Create>
)

export const MobileUserEdit = (props) => {
  const [changingPassword, setChangingPassword] = useState(false)

  return (
    <Edit {...props}>
      <TabbedForm
        toolbar={<Toolbar />}
        redirect={false}
      >
        <FormTab label='summary'>
          <TextField
            source='_id'
            label='Id'
          />
          <TextField
            source='email'
            label='Email'
          />
          <TextInput
            source='phone'
            label='Phone'
            validate={[required(), validatePhone()]}
          />
          <TextInput
            source='name'
            label='Name'
          />
          <CloudinaryField source='photo' label='User photo' />
          {changingPassword &&
            <TextInput
              source='password'
              label='Password'
              type='password'
              validate={required()}
            />}
          <Button
            variant='contained'
            color={changingPassword ? 'primary' : 'secondary'}
            onClick={() => setChangingPassword(!changingPassword)}
          >
            {changingPassword ? 'Discard Changing' : 'Change Password'}
          </Button>
          <SelectInput
            source='status'
            label='Status'
            choices={mobileUsersStatuses}
            validate={required()}
          />
          <BooleanInput
            source='isVerified'
            label='Verified'
          />
          <TextField
            source='verifyToken'
            label='Verify Token'
            type='text'
          />
          <TextField
            source='verifyShortToken'
            label='Verify Short Token'
            type='text'
          />
          <DateField
            source='verifyExpires'
            label='Verify Expires'
          />
          <TextField
            source='resetToken'
            label='Reset Token'
            type='text'
          />
          <TextField
            source='resetShortToken'
            label='Reset Short Token'
            type='text'
          />
          <DateField
            source='resetExpires'
            label='Reset Expires'
            showTime
          />
          <DateField
            source='createdAt'
            label='Created at'
            showTime
          />
          <DateField
            source='updatedAt'
            label='Updated at'
            showTime
          />
          <TextInput
            source='accessCardId'
            label='Access Card ID'
          />
        </FormTab>
        <FormTab path='show' label='Emirates ID'>
          <ImageField source='emiratesData.frontImage.publicUrl' label='Front' />
          <ImageField source='emiratesData.backImage.publicUrl' label='Back' />
          <TextField
            source='emiratesData.mrzData.document_number'
            label='Document ID'
          />
          <TextField
            source='emiratesData.mrzData.given_names_readable'
            label='Names'
          />
          <TextField
            source='emiratesData.mrzData.surname'
            label='Surname'
          />
          <TextField
            source='emiratesData.mrzData.nationality'
            label='Nationality'
          />
          <TextField
            source='emiratesData.mrzData.sex'
            label='Sex'
          />
          <TextField
            source='emiratesData.mrzData.optionals'
            label='Optionals'
          />
          <TextField
            source='emiratesData.mrzData.expiration_date_readable'
            label='Expiration Date'
          />
          <TextField
            source='emiratesData.mrzData.dob_readable'
            label='DOB'
          />
          <TextField
            source='emiratesData.mrzData.issuing_country'
            label='Issuing Country'
          />
          <TextField
            source='emiratesData.mrzData.est_issuing_date_readable'
            label='Issuing Date'
          />

        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export const MobileUserList = (props) => (
  <List
    {...props}
    filters={<Filters />}
  >
    <Datagrid>
      <TextField
        source='email'
        label='Email'
      />
      <TextField
        source='phone'
        label='Phone'
      />
      <TextField
        source='name'
        label='Name'
      />
      <TextField
        source='emiratesData.emiratesId'
        label='Document&nbsp;ID'
      />
      <TextField
        source='accessCardId'
        label='Access Card ID'
      />
      <SelectField
        source='status'
        label='Status'
        choices={mobileUsersStatuses}
      />
      <DateField source='createdAt' showTime />
      <DateField source='updatedAt' showTime />
      {process.env.NODE_ENV === 'development' && <EditButton />}
      <ShowButton style={{ whiteSpace: 'nowrap' }} label='Show ID' />
    </Datagrid>
  </List>
)
