import Change from '../../elements/Change'
import { BooleanInput, DateField, FormTab, required, TabbedForm, TextField, TextInput, } from 'react-admin'
import React from 'react'
import AdminsTab from './tabs/AdminsTab'
import Toolbar from './Toolbar'
import PropertiesTab from './tabs/PropertiesTab'
import CloudinaryField from '../../elements/CloudinaryField'

const CommunityChange = (props) => {
  return (
    <Change {...props}>
      <TabbedForm
        redirect={props.id ? false : 'edit'}
        toolbar={<Toolbar />}
      >
        <FormTab label='summary'>
          {props.id && (
            <TextField
              source='_id'
              label='Id'
            />
          )}
          <TextInput
            source='name'
            label='Name'
            type='text'
            validate={required()}
          />
          <CloudinaryField
            source='logo'
            label='Property Logo'
            required
          />
          <BooleanInput
            source='customBranding'
            label='Custom Branding'
            defaultValue={false}
          />
          <TextInput
            source="branding.mainColor"
            label="Main Color"
            type={'color'}
          />
          <BooleanInput
            source='eventsFeatureActive'
            label='Events Feature Active'
            defaultValue={false}
          />
          {props.id && (
            <>
              <DateField
                source='createdAt'
                label='Created at'
                showTime
              />
              <DateField
                source='updatedAt'
                label='Updated at'
                showTime
              />
            </>
          )}
        </FormTab>
        {props.id && <AdminsTab />}
        {props.id && <PropertiesTab />}
      </TabbedForm>
    </Change>
  )
}
export default CommunityChange
