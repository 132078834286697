import React, { useEffect } from 'react'
import {
  AutocompleteInput,
  Datagrid,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  required,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useNotify,
  useRefresh
} from 'react-admin'

import DateFilters from '../../elements/DateFilters'
import TimestampsFields from '../../elements/TimestampsFields'
import Change from '../../elements/Change'
import toRegex from '../../utils/toRegex'
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles'
import { createMuiTheme } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { useMutation, useRedirect } from 'ra-core'
import debounce from 'lodash/debounce'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import MobileOffIcon from '@material-ui/icons/MobileOff'

export const Filters = (props) => (
  <Filter {...props}>
    <TextInput
      label='Name'
      source='name.$regex'
      alwaysOn
    />
    <TextInput
      source='licencesCode.$regex'
      label='Licences Code'
      alwaysOn
    />
    {DateFilters}
  </Filter>
)

const useStyles = makeStyles(
  theme => ({
    flex: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  })
)

const redTheme = createMuiTheme({ palette: { primary: red } })

const EditToolbar = props => {
  const classes = useStyles()
  const notify = useNotify()
  const redirect = useRedirect()
  const refresh = useRefresh()
  const [archive, { error }] = useMutation({
    type: 'update',
    resource: props.resource,
    payload: {
      id: props.record.id,
      data: {
        isArchived: true
      }
    }
  }, {
    onSuccess: () => redirect('/devices')
  })
  const [detach, { error: detachError }] = useMutation({
    type: 'update',
    resource: props.resource,
    payload: {
      id: props.record.id,
      data: {
        $unset: {
          deviceUniqueId: ''
        }
      }
    }
  }, {
    onSuccess: () => refresh()
  })

  const archiveDevice = debounce(archive, 300)
  const detachDevice = debounce(detach, 300)

  useEffect(() => {
    if (error?.message) {
      console.error(error)
      notify(error.message, 'warning')
    }
  }, [error, notify])

  useEffect(() => {
    if (detachError?.message) {
      console.error(detachError)
      notify(detachError.message, 'warning')
    }
  }, [detachError, notify])
  console.log(props)

  return (
    <Toolbar {...props} className={classes.flex}>
      <SaveButton />
      <MuiThemeProvider theme={redTheme}>
        <Button style={{ marginLeft: 'auto' }} startIcon={<MobileOffIcon />} color='default' variant='text' disabled={!props.record.deviceUniqueId || props.record.deviceUniqueId?.length === 0} redirect={false} onClick={detachDevice}>
          Detach
        </Button>
        <Button startIcon={<DeleteIcon />} color='primary' variant='text' redirect={false} onClick={archiveDevice}>
          Archive
        </Button>
      </MuiThemeProvider>
    </Toolbar>
  )
}

export const DeviceChange = (props) => (
  <Change {...props}>
    <SimpleForm
      toolbar={props.id && <EditToolbar />}
      redirect={!props.id && 'edit'}
    >
      {props.id && (
        <TextField
          source='_id'
          label='Id'
        />
      )}
      <TextInput
        source='name'
        label='Name'
        type='text'
        validate={required()}
      />
      <ReferenceInput
        label='Property'
        source='propertyId'
        reference='properties'
        sort={{ field: 'name', order: 'ASC' }}
        filterToQuery={(v) => toRegex('name')(v)}
        validate={required()}
      >
        <AutocompleteInput optionText='name' emptyText='clear search' />
      </ReferenceInput>
      <TextInput
        source='licencesCode'
        label='Licences Code'
        helperText={!props.id && 'Auto generate if left empty'}
      />
      <TextInput
        disabled
        source='deviceUniqueId'
        label='Device Unique ID'
      />
      {props.id && TimestampsFields}
    </SimpleForm>
  </Change>
)

export const DeviceList = (props) => (
  <List
    {...props}
    filters={<Filters />}
    filter={{
      isArchived: 'false'
    }}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField
        source='name'
        label='Name'
      />
      <ReferenceField
        label='Property'
        source='propertyId'
        reference='properties'
      >
        <TextField source='name' />
      </ReferenceField>
      {TimestampsFields}
      <EditButton />
    </Datagrid>
  </List>
)
