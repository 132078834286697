import * as React from 'react'
import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Avatar, Card } from '@material-ui/core'
import { styled } from '@material-ui/styles'
import LockIcon from '@material-ui/icons/Lock'

import OTPForm from './components/OTPForm'

export const VerifyToken = (props) => {
  const { children, backgroundImage, ...rest } = props
  const containerRef = useRef()
  let backgroundImageLoaded = false

  const updateBackgroundImage = () => {
    if (!backgroundImageLoaded && containerRef.current) {
      containerRef.current.style.backgroundImage = `url(${backgroundImage})`
      backgroundImageLoaded = true
    }
  }

  const lazyLoadBackgroundImage = () => {
    if (backgroundImage) {
      const img = new Image()
      img.onload = updateBackgroundImage
      img.src = backgroundImage
    }
  }

  useEffect(() => {
    if (!backgroundImageLoaded) {
      lazyLoadBackgroundImage()
    }
  })
  return (
    <Root {...rest} ref={containerRef}>
      <Card className={VerifyTokenClasses.card}>
        <div className={VerifyTokenClasses.avatar}>
          <Avatar className={VerifyTokenClasses.icon}>
            <LockIcon />
          </Avatar>
        </div>
        {children}
      </Card>
    </Root>
  )
}

const PREFIX = 'RaVerifyToken'
export const VerifyTokenClasses = {
  card: `${PREFIX}-card`,
  avatar: `${PREFIX}-avatar`,
  icon: `${PREFIX}-icon`,
}

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  height: '1px',
  alignItems: 'center',
  justifyContent: 'flex-start',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundImage:
    'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',

  [`& .${VerifyTokenClasses.card}`]: {
    minWidth: 300,
    marginTop: '6em',
  },
  [`& .${VerifyTokenClasses.avatar}`]: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  [`& .${VerifyTokenClasses.icon}`]: {
    backgroundColor: '#c5c5c5',
  },
}))

VerifyToken.propTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
}

VerifyToken.defaultProps = {
  children: <OTPForm />,
}

export default VerifyToken

