import React from 'react'

import {
  DateTimeInput,
  required,
  SelectInput,
  TextInput,
  ReferenceInput, AutocompleteInput
} from 'react-admin'

import FilteredReferenceInput from '../../elements/inputs/FilteredReferenceInput'
import { validatePhone } from '../../validators'
import toRegex from '../../utils/toRegex'

export const EventsInputsCreate = [
  <ReferenceInput
    label='Property Id'
    key='propertyId'
    source='propertyId'
    reference='properties'
    filterToQuery={(v) => toRegex('name')(v)}
    sort={{ field: 'name', order: 'ASC' }}
    validate={required()}
  >
    <AutocompleteInput optionText='name' emptyText='clear search' />
  </ReferenceInput>,
  <TextInput
    key='message'
    source='message'
    label='Message'
    type='text'
  />,
  <DateTimeInput
    key='date'
    source='date'
    label='Date'
    validate={required()}
  />
]

export const EventsInputsEdit = EventsInputsCreate

const getGuestInput = () => (
  <ReferenceInput
    label='Guest Id'
    key='guestId'
    source='guestId'
    reference='mobileUsers'
    sort={{ field: 'email', order: 'ASC' }}
    filterToQuery={(v) => toRegex('email')(v)}
  >
    <AutocompleteInput optionText='email' emptyText='clear search' />
  </ReferenceInput>
)

const getGuestNameInput = () => (
  <TextInput
    key='guestName'
    source='guestName'
    label='Guest Name'
    type='text'
  />
)

const getGuestMobileNumberInput = () => (
  <TextInput
    key='guestMobileNumber'
    source='guestMobileNumber'
    label='Guest Mobile Number'
    type='text'
    validate={[required(), validatePhone()]}
  />
)

const getHostInput = () => (
  <ReferenceInput
    label='Host Id'
    key='hostId'
    source='hostId'
    reference='mobileUsers'
    sort={{ field: 'email', order: 'ASC' }}
    filterToQuery={(v) => toRegex('email')(v)}
    validate={required()}
  >
    <AutocompleteInput optionText='email' emptyText='clear search' />
  </ReferenceInput>
)

const getFlatInput = () => (
  <FilteredReferenceInput
    label='Flat Id'
    key='flatId'
    source='flatId'
    reference='flats'
    sort={{ field: 'flatNumber', order: 'ASC' }}
    filterToQuery={(v) => toRegex('flatNumber')(v)}
    validate={required()}
    filterSource='propertyId'
    filterDest='propertyId'
  >
    <AutocompleteInput optionText='flatNumber' emptyText='clear search' />
  </FilteredReferenceInput>
)

const getDeviceInput = () => (
  <ReferenceInput
    label='Device Id'
    key='deviceId'
    source='deviceId'
    reference='devices'
    sort={{ field: 'name', order: 'ASC' }}
    filterToQuery={(v) => toRegex('name')(v)}
    validate={required()}
  >
    <AutocompleteInput optionText='name' emptyText='clear search' />
  </ReferenceInput>
)

const getEntryInput = () => (
  <SelectInput
    key='entryType'
    source='entryType'
    choices={[
      { id: 'nfc', name: 'NFC' },
      { id: 'qrCode', name: 'QR code' },
      { id: 'manual', name: 'Manual' }
    ]}
    validate={required()}
  />
)

const getLeaveInput = () => (
  <SelectInput
    key='leaveType'
    source='leaveType'
    choices={[
      { id: 'nfc', name: 'NFC' },
      { id: 'qrCode', name: 'QR code' },
      { id: 'manual', name: 'Manual' },
      { id: 'autoSignOut', name: 'Auto Sign out' }
    ]}
    validate={required()}
  />
)

const getDeliveryCompanyInput = () => (
  <ReferenceInput
    label='Delivery company'
    key='companyId'
    source='companyId'
    reference='deliveryCompanies'
    sort={{ field: 'name', order: 'ASC' }}
    filterToQuery={(v) => toRegex('name')(v)}
    validate={required()}
  >
    <AutocompleteInput optionText='name' emptyText='clear search' />
  </ReferenceInput>
)

const getAccessesInput = () => (
  <ReferenceInput
    source='accessId'
    label='Access ID'
    reference='accesses'
    filterToQuery={searchText => ({ 'accessCode.$regex': searchText })}
  >
    <AutocompleteInput optionText='accessCode' />
  </ReferenceInput>
)

const getDelivererInput = () => (
  <ReferenceInput
    source='delivererId'
    label='Mobile User'
    reference='mobileUsers'
    filterToQuery={(v) => toRegex('email')(v)}
  >
    <AutocompleteInput optionText='email' emptyText='clear search' />
  </ReferenceInput>
)

export const EnterEventsInputsCreate = [
  getGuestInput(),
  getHostInput(),
  getFlatInput(),
  getDeviceInput(),
  getEntryInput(),
  getGuestNameInput(),
  getGuestMobileNumberInput(),
  getAccessesInput()
]

export const EnterEventsInputsEdit = EnterEventsInputsCreate

export const LeaveEventsInputsCreate = [
  getGuestInput(),
  getHostInput(),
  getFlatInput(),
  getDeviceInput(),
  getLeaveInput(),
  getAccessesInput()
]

export const LeaveEventsInputsEdit = LeaveEventsInputsCreate

export const ReportEventsInputsCreate = [
  getGuestInput(),
  getHostInput(),
  getFlatInput(),
  getAccessesInput()
]

export const ReportEventsInputsEdit = ReportEventsInputsCreate

export const DeliveryEventsInputsCreate = [
  getDeviceInput(),
  getDeliveryCompanyInput(),
  getDelivererInput(),
  getHostInput(),
  getFlatInput()
]

export const DeliveryEventsInputsEdit = DeliveryEventsInputsCreate
