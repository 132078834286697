import { Datagrid, FormTab, Pagination, ReferenceField, ReferenceManyField, TextField } from 'react-admin'
import React from 'react'
import { Button, Typography } from '@material-ui/core'
import TimestampsFields from '../../elements/TimestampsFields'
import { Link } from 'react-router-dom'
import { useFormState } from 'react-final-form'

const ReportCasesTab = ({ ...props }) => {
  const state = useFormState()
  return (
    <FormTab {...props} label='report cases'>
      <Button
        component={Link}
        to={{
          pathname: '/reportCases/create',
          state: {
            record: {
              flatId: state.values.id
            }
          }
        }}
        color='primary'
        variant='contained'
      >
        Create New
      </Button>

      <Typography variant='body1' style={{ margin: '50px 0 20px' }}>
        Report Cases:
      </Typography>

      <ReferenceManyField
        addLabel={false}
        fullWidth
        reference='reportCases'
        target='flatId'
        sort={{
          field: 'createdAt',
          order: 'DESC'
        }}
        pagination={<Pagination />}
      >
        <Datagrid>
          <ReferenceField label='Host' source='hostId' reference='mobileUsers'>
            <TextField source='email' />
          </ReferenceField>
          <TextField source='status' />
          {TimestampsFields}
        </Datagrid>
      </ReferenceManyField>
    </FormTab>
  )
}

export default ReportCasesTab
