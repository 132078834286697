import React, { createElement, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles, useMediaQuery } from '@material-ui/core'
import { getResources, MenuItemLink, useQuery } from 'react-admin'
import { withRouter } from 'react-router-dom'
import Submenu from './Submenu'
import startCase from 'lodash/startCase'
import { PeopleOutlineSharp, SettingsInputComponent } from '@material-ui/icons'
import useUser from '../../../hooks/useUser'

const userSubmenu = [
  'propertyManagers',
  'propertyAdmins',
  'deliveryAdmins',
  'admins',
  'mobileUsers',
  'subAdmins',
]

const devSubmenu = [
  'qrAccesses',
  'appAccesses',
  'sms',
  'pushNotifications',
  'emails',
  'users',
  'mobileUsersFlats',
  'deliveryCompanyDeliverer',
  'reportCases',
  'flats',
  'events',
  'propertyNotifications'
]

const propertySettings = [
  'propertyManagersProperties',
  'propertyAdminsProperties',
  'devices'
]

const notInMainMenu = [
  ...userSubmenu,
  ...devSubmenu,
  ...propertySettings,
  'communityAdmins',
  'systemLogs',
  'licenses',
]

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4)
  },
  nestedSmall: {
    paddingLeft: theme.spacing(3)
  }
}))

const Menu = ({ onMenuClick, logout, location }) => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const open = useSelector(state => state.admin.ui.sidebarOpen)
  const resources = useSelector(getResources)
  const classes = useStyles()
  const { id } = useUser()
  const { data: adminData } = useQuery({
    type: 'getOne',
    resource: 'users',
    payload: { id }
  })

  const currentBasePath = useMemo(() => {
    return location.pathname.split('/')[1] || ''
  }, [location.pathname])

  return (
    <div>
      {resources
        .filter(resource => !notInMainMenu.includes(resource.name))
        .map(resource => (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={resource.options?.label || startCase(resource.name)}
            leftIcon={createElement(resource.icon)}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        ))}

      <Submenu
        label='Users'
        icon={PeopleOutlineSharp}
        sidebarIsOpen={open}
        active={userSubmenu.includes(currentBasePath)}
      >
        {resources
          .filter(resource => adminData?.type === 'admins'
            ? userSubmenu.includes(resource.name)
            : userSubmenu
              .filter(x => !['admins', 'subAdmins', 'mobileUsers'].includes(x))
              .concat(['admins', 'subAdmins', 'mobileUsers'].filter(x => !userSubmenu.includes(x)))
              .includes(resource.name)
          )
          .map(resource => {
            return (
              <MenuItemLink
                key={resource.name}
                to={`/${resource.name}`}
                primaryText={resource.options?.label || startCase(resource.name)}
                leftIcon={createElement(resource.icon)}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                className={open ? classes.nested : classes.nestedSmall}
              />
            )
          })}
      </Submenu>
      <Submenu
        label='Property Settings'
        icon={SettingsInputComponent}
        sidebarIsOpen={open}
        active={propertySettings.includes(currentBasePath)}
      >
        {resources
          .filter(resource => propertySettings.includes(resource.name))
          .map(resource => {
            return (
              <MenuItemLink
                key={resource.name}
                to={`/${resource.name}`}
                primaryText={resource.options?.label || startCase(resource.name)}
                leftIcon={createElement(resource.icon)}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                className={open ? classes.nested : classes.nestedSmall}
              />
            )
          })}
      </Submenu>
      {
        process.env.NODE_ENV === 'development' &&
          <Submenu
            label='Dev'
            sidebarIsOpen={open}
            active={devSubmenu.includes(currentBasePath)}
          >
            {resources
              .filter(resource => devSubmenu.includes(resource.name))
              .map(resource => {
                return (
                  <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={resource.options?.label || startCase(resource.name)}
                    leftIcon={createElement(resource.icon)}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    className={open ? classes.nested : classes.nestedSmall}
                  />
                )
              })}
          </Submenu>
      }
      {resources
        .filter(resource => adminData?.type === 'admins' && resource.name === 'systemLogs')
        .map(resource => (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={resource.options?.label || startCase(resource.name)}
            leftIcon={createElement(resource.icon)}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        ))}
      {resources
        .filter(resource => adminData?.type === 'admins' && resource.name === 'licenses')
        .map(resource => (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={resource.options?.label || startCase(resource.name)}
            leftIcon={createElement(resource.icon)}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        ))}
      {isXSmall && logout}
    </div>
  )
}

export default withRouter(Menu)
