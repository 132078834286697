export const EVENT_TYPE = {
  ENTER_EVENTS: 'enterEvents',
  LEAVE_EVENTS: 'leaveEvents',
  REPORT_EVENTS: 'reportEvents',
  DELIVERY_EVENTS: 'deliveryEvents'
}

export const eventTypeChoices = [
  { id: EVENT_TYPE.ENTER_EVENTS, name: EVENT_TYPE.ENTER_EVENTS },
  { id: EVENT_TYPE.LEAVE_EVENTS, name: EVENT_TYPE.LEAVE_EVENTS },
  { id: EVENT_TYPE.REPORT_EVENTS, name: EVENT_TYPE.REPORT_EVENTS },
  { id: EVENT_TYPE.DELIVERY_EVENTS, name: EVENT_TYPE.DELIVERY_EVENTS }
]
