import React from 'react'
import {
  BooleanInput,
  Create,
  DateField,
  Edit,
  FormTab,
  required,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
} from 'react-admin'
import { validatePhone } from '../../validators'
import Toolbar from '../../elements/Layout/Toolbar'
import ResetPasswordButton from '../../elements/ResetPasswordButton'

export const PropertyAdminCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source='name'
        label='Name'
        validate={required()}
      />
      <TextInput
        source='email'
        label='Email'
        type='email'
        validate={required()}
      />
      <TextInput
        source='phone'
        label='Phone'
        type='text'
        validate={validatePhone()}
      />
    </SimpleForm>
  </Create>
)

export const PropertyAdminEdit = (props) => {
  return (
    <Edit {...props}>
      <TabbedForm
        redirect={false}
        toolbar={<Toolbar />}
      >
        <FormTab label='summary'>
          <TextField
            source='_id'
            label='Id'
          />
          <TextInput
            source='name'
            label='Name'
            type='text'
            validate={required()}
          />
          <TextInput
            source='email'
            label='Email'
            type='email'
            validate={required()}
          />
          <TextInput
            source='phone'
            label='Phone'
            type='text'
            validate={validatePhone()}
          />
          <ResetPasswordButton />
          <BooleanInput
            source='isVerified'
            label='Verified'
            validate={required()}
          />
          <TextField
            source='verifyToken'
            label='Verify Token'
            type='text'
            validate={required()}
          />
          <TextField
            source='verifyShortToken'
            label='Verify Short Token'
            type='text'
            validate={required()}
          />
          <DateField
            source='verifyExpires'
            label='Verify Expires'
            validate={required()}
          />
          <TextField
            source='resetToken'
            label='Reset Token'
            type='text'
            validate={required()}
          />
          <TextField
            source='resetShortToken'
            label='Reset Short Token'
            type='text'
            validate={required()}
          />
          <DateField
            source='resetExpires'
            label='Reset Expires'
            validate={required()}
            showTime
          />
          <DateField
            source='createdAt'
            label='Created at'
            showTime
          />
          <DateField
            source='updatedAt'
            label='Updated at'
            showTime
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
