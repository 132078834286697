import {
  AutocompleteInput,
  Datagrid,
  DateField,
  EditButton,
  FormTab,
  FunctionField,
  Pagination,
  ReferenceArrayField,
  ReferenceField,
  ReferenceInput,
  TextField,
  useNotify,
  useRefresh,
  useUpdate
} from 'react-admin'
import React from 'react'
import { Button, Typography } from '@material-ui/core'
import toRegex from '../../../utils/toRegex'
import { useFormState } from 'react-final-form'
import { useMutation } from 'ra-core'
import DeleteIcon from '@material-ui/icons/Delete'

const EditConnectedButton = (props) => <EditButton {...props} basePath='/properties' />

const PropertiesTabs = ({ ...props }) => {
  const state = useFormState()
  const notify = useNotify()
  const refresh = useRefresh()
  const [assignProperty] = useUpdate(
    'communities',
    state.values.id,
    {
      properties: [...state.values.properties, state.values.propertyId]
    }
  )
  const [mutate] = useMutation()

  return (
    <FormTab {...props} label='properties'>
      <ReferenceInput
        source='propertyId'
        reference='properties'
        label='Assign Property'
        allowEmpty
        sort={{
          field: 'name',
          order: 'ASC'
        }}
        filter={{
          _id: {
            $nin: state.values.properties
          }
        }}
        filterToQuery={(v) => toRegex('name')(v)}
      >
        <AutocompleteInput
          optionText='name'
          emptyText='clear search'
        />
      </ReferenceInput>
      <Button
        onClick={() => {
          assignProperty({}, {
            onSuccess: () => {
              refresh()
            },
            onFailure: (error) => {
              let mes = error.message
              if (error.code === 409) {
                mes = 'Property already assigned'
              }
              notify(mes, 'error')
            }
          })
        }}
        disabled={!state.values.propertyId}
        color='primary'
        variant='contained'
      >
        Assign
      </Button>

      <Typography variant='body1' style={{ margin: '50px 0 20px' }}>
        Assigned properties:
      </Typography>

      <ReferenceArrayField
        addLabel={false}
        fullWidth
        reference='properties'
        source='properties'
        sort={{
          field: 'createdAt',
          order: 'DESC'
        }}
        pagination={<Pagination />}
      >
        <Datagrid>
          <ReferenceField label='Property Admin' source='_id' reference='properties'>
            <TextField source='name' />
          </ReferenceField>
          <ReferenceField label='CreatedAt' source='_id' reference='properties' link={false}>
            <DateField source='createdAt' showTime />
          </ReferenceField>
          <ReferenceField label='UpdatedAt' source='_id' reference='properties' link={false}>
            <DateField source='updatedAt' showTime />
          </ReferenceField>

          <ReferenceField label='' source='_id' reference='properties'>
            <EditConnectedButton />
          </ReferenceField>
          <FunctionField label='Unlink' render={record => (
            <Button
              style={{
                color: 'red',
              }}
              startIcon={<DeleteIcon />}
              onClick={() => {
                mutate({
                  type: 'update',
                  resource: 'communities',
                  payload: {
                    id: state.values._id,
                    data: {
                      properties: state.values.properties.filter(id => id !== record._id)
                    }
                  },
                })
              }}
            >
              Unlink
            </Button>
          )} />
        </Datagrid>
      </ReferenceArrayField>
    </FormTab>
  )
}

export default PropertiesTabs
