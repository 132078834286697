import React from 'react'

import { DateField } from 'react-admin'

const TimestampsFields = [
  <DateField
    key='createdAt'
    source='createdAt'
    label='Created at'
    showTime
  />,
  <DateField
    key='updatedAt'
    source='updatedAt'
    label='Updated at'
    showTime
  />
]

export default TimestampsFields
