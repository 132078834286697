import {
  Datagrid,
  DateField,
  Filter,
  List,
  ReferenceInput,
  RichTextField,
  SelectInput,
  TextField,
  TextInput
} from 'react-admin'
import React from 'react'
import DateFilters from '../../elements/DateFilters'

export const Filters = (props) => (
  <Filter {...props}>
    <ReferenceInput
      source='propertyId'
      label='Property'
      reference='properties'
      alwaysOn
    >
      <SelectInput optionText='name' />
    </ReferenceInput>
    <TextInput
      source='title.$regex'
      label='Title'
      alwaysOn
    />
    {DateFilters}
  </Filter>
)

const PropertyNotificationsList = (props) => (
  <List
    {...props}
    filters={<Filters />}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField
        source='title'
        label='Title'
      />
      <RichTextField
        source='text'
        label='Message'
      />
      <DateField source='createdAt' showTime />
      <DateField source='updatedAt' showTime />
    </Datagrid>
  </List>
)

export default PropertyNotificationsList
