import React from 'react'
import { AutocompleteInput, FormTab, ReferenceInput, required, TabbedForm, TextField, TextInput } from 'react-admin'
import Change from '../../elements/Change'
import Toolbar from '../../elements/Layout/Toolbar'
import toRegex from '../../utils/toRegex'
import TimestampsFields from '../../elements/TimestampsFields'
import UsersTab from './UsersTab'
import ReportCasesTab from './ReportCasesTab'

const FlatChange = (props) => (
  <Change {...props}>
    <TabbedForm
      toolbar={props.id && <Toolbar hideDelete />}
      redirect={!props.id && 'edit'}
    >
      <FormTab label='Summary'>
        {props.id && (
          <TextField
            source='_id'
            label='Id'
          />
        )}
        <TextInput
          source='flatNumber'
          label='Flat number'
          validate={required()}
        />
        <ReferenceInput
          label='Property'
          source='propertyId'
          reference='properties'
          sort={{ field: 'name', order: 'ASC' }}
          filterToQuery={(v) => toRegex('name')(v)}
          validate={required()}
        >
          <AutocompleteInput optionText='name' emptyText='clear search' />
        </ReferenceInput>
        {props.id && TimestampsFields}
      </FormTab>
      {props.id && <UsersTab />}
      {props.id && <ReportCasesTab />}
    </TabbedForm>
  </Change>
)
export default FlatChange
