import React, { useEffect } from 'react'
import {
  BooleanInput,
  DateInput,
  FormDataConsumer,
  Labeled,
  required,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useNotify
} from 'react-admin'

import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import upperFirst from 'lodash/upperFirst'

import TimePicker from '../../../elements/TimePicker'
import TimestampsFields from '../../../elements/TimestampsFields'
import Change from '../../../elements/Change'
import AccessFields from '../AccessFields'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import debounce from 'lodash/debounce'
import { useMutation, useRedirect } from 'ra-core'
import { createMuiTheme } from '@material-ui/core'
import { red } from '@material-ui/core/colors'

const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

const dateRangeValidation = (relatedField) => (value, allValues) => {
  return value ? undefined : isEmpty(get(allValues, relatedField)) ? undefined : 'ra.validation.required'
}

const useStyles = makeStyles(
  theme => ({
    rangeTime: {
      paddingLeft: theme.spacing(2)
    },
    rangeTimeGroup: {
      paddingLeft: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    flex: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  })
)

const redTheme = createMuiTheme({ palette: { primary: red } })

const EditToolbar = props => {
  const classes = useStyles()
  const notify = useNotify()
  const redirect = useRedirect()
  const [archive, { error }] = useMutation({
    type: 'update',
    resource: props.resource,
    payload: {
      id: props.record.id,
      data: {
        status: 'archived'
      }
    }
  }, {
    onSuccess: () => redirect('/appAccesses')
  })

  const archiveAccess = debounce(archive, 300)

  useEffect(() => {
    if (error?.message) {
      console.error(error)
      notify(error.message, 'warning')
    }
  }, [error, notify])

  return (
    <Toolbar {...props} className={classes.flex}>
      <SaveButton />
      <MuiThemeProvider theme={redTheme}>
        <Button startIcon={<DeleteIcon />} color='primary' variant='text' redirect={false} onClick={archiveAccess}>
          Archive
        </Button>
      </MuiThemeProvider>
    </Toolbar>
  )
}

export const AppAccessChange = (props) => {
  const classes = useStyles()

  return (
    <Change {...props}>
      <SimpleForm
        toolbar={props.id && <EditToolbar />}
        redirect={!props.id ? 'edit' : false}
      >
        {props.id && (
          <TextField
            source='_id'
            label='Id'
          />
        )}
        <AccessFields />
        <BooleanInput
          source='isOneTimeAccess'
          label='One time access'
        />
        <FormDataConsumer>
          {({ formData }) => !formData.isOneTimeAccess &&
            <>
              <DateInput
                source='validFrom'
                label='Valid from'
                validate={required()}
              />
              <DateInput
                source='validTil'
                label='Valid til'
                validate={required()}
              />
              <TextInput
                source='note'
                label='Title/Note'
              />
              <Labeled label='Days'>
                <span>
                  {days.map(day => (
                    <Labeled key={day} label={upperFirst(day)} className={classes.rangeTimeGroup}>
                      <div className={classes.rangeTime}>
                        <TimePicker
                          source={`days.${day}.from`}
                          label='From'
                          validate={dateRangeValidation(`days.${day}.to`)}
                        />
                        <TimePicker
                          source={`days.${day}.to`}
                          label='To'
                          validate={dateRangeValidation(`days.${day}.from`)}
                        />
                      </div>
                    </Labeled>
                  ))}
                </span>
              </Labeled>
            </>}
        </FormDataConsumer>
        {props.id && TimestampsFields}
      </SimpleForm>
    </Change>
  )
}
