import {
  Datagrid,
  DeleteButton,
  downloadCSV,
  Filter,
  List,
  ReferenceField,
  ShowButton,
  TextField,
  TextInput
} from 'react-admin'
import * as jsonExport from 'jsonexport/dist'
import React from 'react'
import DateFilters from '../../elements/DateFilters'
import TimestampsFields from '../../elements/TimestampsFields'
import { format } from 'date-fns'

const exporter = (propertyAdminsProperties, fetchRelatedRecords) => {
  let properties = {}
  let propertyAdmins = {}
  const numbersOfRequests = Math.ceil(propertyAdminsProperties.length / 100)
  const promises = []
  for (let i = 0; i < numbersOfRequests; i++) {
    promises.push(new Promise((resolve) => {
      fetchRelatedRecords(propertyAdminsProperties.slice(i * 100, (i + 1) * 100), 'propertyId', 'properties')
        .then((data) => {
          properties = { ...properties, ...data }
          resolve()
        })
    }))
    promises.push(new Promise((resolve) => {
      fetchRelatedRecords(propertyAdminsProperties.slice(i * 100, (i + 1) * 100), 'propertyAdminId', 'propertyAdmins')
        .then((data) => {
          propertyAdmins = { ...propertyAdmins, ...data }
          resolve()
        })
    }))
  }

  Promise.all(promises).then(() => {
    const propertyAdminsPropertiesForExport = propertyAdminsProperties.map(propertyAdminsProperty => {
      const { propertyId, propertyAdminId } = propertyAdminsProperty
      propertyAdminsProperty.propertyAdmin = propertyAdmins[propertyAdminId]?.name
      propertyAdminsProperty.property = properties[propertyId]?.name
      delete propertyAdminsProperty._id
      delete propertyAdminsProperty.__v
      delete propertyAdminsProperty.propertyId
      delete propertyAdminsProperty.propertyAdminId
      return propertyAdminsProperty
    });
    jsonExport(propertyAdminsPropertiesForExport, {
      headers: ['id', 'propertyAdmin', 'property', 'createdAt', 'updatedAt']
    }, (err, csv) => {
      downloadCSV(csv, `propertyAdminsProperties-${format(new Date(), 'dd-MM-yyyy')}`)
    })
  })
}

export const Filters = (props) => (
  <Filter {...props}>
    <TextInput
      label='Email'
      source='email.$regex'
      alwaysOn
    />
    {DateFilters}
  </Filter>
)
const PropertyAdminPropertiesList = (props) => (
  <List
    {...props}
    filters={<Filters />}
    exporter={exporter}
  >
    <Datagrid>
      <ReferenceField label='Property Admin' source='propertyAdminId' reference='propertyAdmins'>
        <TextField source='email' />
      </ReferenceField>
      <ReferenceField label='Property' source='propertyId' reference='properties'>
        <TextField source='name' />
      </ReferenceField>
      {TimestampsFields}
      <ShowButton />
      <DeleteButton />
    </Datagrid>
  </List>
)

export default PropertyAdminPropertiesList
