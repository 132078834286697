import {
  Datagrid,
  DateField, DeleteButton,
  FormTab,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  EditButton,
  TextField, AutocompleteInput,
  ReferenceInput,
  useCreate, useRefresh, useNotify
} from 'react-admin'
import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { useFormState } from 'react-final-form'
import toRegex from '../../../utils/toRegex'

const EditConnectedButton = (props) => <EditButton {...props} basePath='/propertyManagers' />

const ManagersTab = ({ ...props }) => {
  const state = useFormState()
  const notify = useNotify()
  const refresh = useRefresh()
  const [create] = useCreate(
    'propertyManagersProperties',
    {
      propertyManagerId: state.values.propertyManagerId,
      propertyId: state.values.id
    }
  )

  return (
    <FormTab {...props} label='managers'>
      <ReferenceInput
        source='propertyManagerId'
        reference='propertyManagers'
        label='Assign Property Manager'
        allowEmpty
        sort={{
          field: 'name',
          order: 'ASC'
        }}
        filterToQuery={(v) => toRegex('name')(v)}
      >
        <AutocompleteInput
          optionText='name'
          emptyText='clear search'
        />
      </ReferenceInput>
      <Button
        onClick={() => {
          create({}, {
            onSuccess: () => {
              refresh()
            },
            onFailure: (error) => {
              let mes = error.message
              if (error.code === 409) {
                mes = 'Property manager already exists.'
              }
              notify(mes, 'error')
            }
          })
        }}
        disabled={!state.values.propertyManagerId}
        color='primary'
        variant='contained'
      >
        Assign
      </Button>

      <Typography variant='body1' style={{ margin: '50px 0 20px' }}>
        Assigned property managers:
      </Typography>

      <ReferenceManyField
        addLabel={false}
        fullWidth
        reference='propertyManagersProperties'
        target='propertyId'
        sort={{
          field: 'createdAt',
          order: 'DESC'
        }}
        pagination={<Pagination />}
      >
        <Datagrid>
          <ReferenceField label='Property Managers' source='propertyManagerId' reference='propertyManagers'>
            <TextField source='name' />
          </ReferenceField>
          <ReferenceField label='CreatedAt' source='propertyManagerId' reference='propertyManagers' link={false}>
            <DateField source='createdAt' showTime />
          </ReferenceField>
          <ReferenceField label='UpdatedAt' source='propertyManagerId' reference='propertyManagers' link={false}>
            <DateField source='updatedAt' showTime />
          </ReferenceField>
          <ReferenceField label='' source='propertyManagerId' reference='propertyManagers'>
            <EditConnectedButton />
          </ReferenceField>
          <DeleteButton label='Unlink' redirect={false} />
        </Datagrid>
      </ReferenceManyField>
    </FormTab>
  )
}

export default ManagersTab
