import englishMessages from 'ra-language-english'

const messages = {
  createdPropertyAdminsProperty: 'New Property admin has been assigned to property',
  propertyAdminPropertyDeleted: 'Property Admin has been deleted',
  validation: {
    mobilePhone: 'Not valid phone number'
  },
  login: {
    email: {
      required: 'Email address is required'
    },
    password: {
      required: 'Password is required'
    },
    invalidEmailOrPassword: 'Invalid email address or password'
  },
  verifyToken: {
    message: 'Two-factor authentication',
    invalidOrExpiredVerifyCode: 'Invalid or expired code',
    otp: {
      label: 'Code',
      required: 'Code is required'
    }
  },
}

export default {
  ...englishMessages,
  messages
}
