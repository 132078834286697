import { Datagrid, DateField, EditButton, List, SelectField, TextField, } from 'react-admin'
import TimestampsFields from '../../elements/TimestampsFields'
import React from 'react'
import { LicenseField } from './index'

const LicensesList = (props) => (
  <List
    {...props}
    exporter={false}
    hasEdit={false}
    hasCreate={true}
  >
    <Datagrid>
      <TextField
        source='organizationName'
        label='Organization Name'
      />
      <LicenseField
        source='licenseCode'
        label='License Key'
      />
      <DateField
        source='expiryDate'
        label='Expiry Date'
      />
      <SelectField
        choices={[
          { id: 0, name: 'Pending' },
          { id: 1, name: 'Active' },
          { id: 2, name: 'Revoked' },
          { id: 3, name: 'Expired' },
          { id: 4, name: 'Archived' },
        ]}
        source='status'
        label='Status'
      />
      <DateField
        source='lastCheckedAt'
        label='Last Checked at'
        showTime
      />
      {TimestampsFields}
      <EditButton />
    </Datagrid>
  </List>
)
export default LicensesList
