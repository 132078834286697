import {
  Datagrid,
  FormTab,
  Pagination,
  ReferenceManyField,
  TextField,
  TextInput,
  useCreate,
  useNotify,
  useRefresh
} from 'react-admin'
import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { useFormState } from 'react-final-form'
import TimestampsFields from '../../../elements/TimestampsFields'

const NotificationsTab = ({ ...props }) => {
  const state = useFormState()
  const notify = useNotify()
  const refresh = useRefresh()
  const [create] = useCreate(
    'propertyNotifications',
    {
      text: state.values.text,
      title: state.values.title,
      propertyId: state.values.id
    }
  )

  return (
    <FormTab {...props} label='notifications'>
      <TextInput
        source='title'
        label='Notification Title'
      />
      <TextInput
        source='text'
        label='Notification Text'
      />
      <Button
        onClick={() => {
          create({}, {
            onSuccess: () => {
              refresh()
            },
            onFailure: (error) => {
              notify(error.message, 'error')
            }
          })
        }}
        disabled={!state.values.title || !state.values.text}
        color='primary'
        variant='contained'
      >
        Create
      </Button>

      <Typography variant='body1' style={{ margin: '50px 0 20px' }}>
        Notifications:
      </Typography>

      <ReferenceManyField
        addLabel={false}
        fullWidth
        reference='propertyNotifications'
        target='propertyId'
        sort={{
          field: 'createdAt',
          order: 'DESC'
        }}
        pagination={<Pagination />}
      >
        <Datagrid>
          <TextField source='title' />
          <TextField source='text' />
          {TimestampsFields}
        </Datagrid>
      </ReferenceManyField>
    </FormTab>
  )
}

export default NotificationsTab
