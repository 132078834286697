import React from 'react'
import {
  SimpleForm,
  Create,
  TextField,
  SimpleShowLayout,
  Show,
  ReferenceField,
  ReferenceInput,
  SelectInput
} from 'react-admin'
import TimestampsFields from '../../elements/TimestampsFields'

export const PropertyManagersPropertiesCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput label='Property Manager' source='propertyManagerId' reference='propertyManagers'>
        <SelectInput optionText='email' />
      </ReferenceInput>
      <ReferenceInput label='Property' source='propertyId' reference='properties'>
        <SelectInput optionText='name' />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

export const PropertyManagersPropertiesShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField
          source='_id'
          label='Id'
        />
        <ReferenceField label='Property Manager' source='propertyManagerId' reference='propertyManagers'>
          <TextField source='email' />
        </ReferenceField>
        <ReferenceField label='Property' source='propertyId' reference='properties'>
          <TextField source='name' />
        </ReferenceField>
        {TimestampsFields}
      </SimpleShowLayout>
    </Show>
  )
}
