import {
  Datagrid,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  TextField
} from 'react-admin'
import { statuses } from './enums'
import React from 'react'
import TimestampsFields from '../../elements/TimestampsFields'
import DateFilters from '../../elements/DateFilters'

export const Filters = (props) => (
  <Filter {...props}>
    <SelectInput
      source='status'
      choices={statuses}
      alwaysOn
    />
    <ReferenceInput
      source='mobileUserId'
      label='Mobile User'
      reference='mobileUsers'
      alwaysOn
    >
      <SelectInput optionText='name' />
    </ReferenceInput>
    {DateFilters}
  </Filter>
)

const MobileUsersFlatsList = (props) => (
  <List
    {...props}
    filters={<Filters />}
    bulkActionButtons={false}
  >
    <Datagrid>
      <ReferenceField
        source='mobileUserId'
        label='Mobile User'
        reference='mobileUsers'
      >
        <TextField source='phone' />
      </ReferenceField>
      <ReferenceField
        source='flatId'
        label='Flat'
        reference='flats'
      >
        <TextField source='flatNumber' />
      </ReferenceField>
      <SelectField
        source='status'
        label='Status'
        choices={statuses}
      />
      {TimestampsFields}
      <EditButton />
    </Datagrid>
  </List>
)

export default MobileUsersFlatsList
