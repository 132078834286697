import Change from '../../elements/Change'
import {
  BooleanInput,
  DateField,
  FormTab,
  NumberInput,
  required,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  useQuery
} from 'react-admin'
import CloudinaryField from '../../elements/CloudinaryField'
import React from 'react'
import createChoices from '../../utils/createChoices'
import { ESecurityLevel } from './enums'
import AdminsTab from './tabs/AdminsTab'
import Toolbar from './Toolbar'
import ManagersTab from './tabs/ManagersTab'
import FlatsTab from './tabs/FlatsTab'
import DevicesTab from './tabs/DevicesTab'
import NotificationsTab from './tabs/NotificationTab'
import useUser from '../../hooks/useUser'

const PropertyChange = (props) => {
  const { id } = useUser()
  const { data: adminData } = useQuery({
    type: 'getOne',
    resource: 'users',
    payload: { id }
  })

  return (
    <Change {...props}>
      <TabbedForm
        redirect={props.id ? false : 'edit'}
        toolbar={<Toolbar />}
      >
        <FormTab label='summary'>
          {props.id && (
            <TextField
              source='_id'
              label='Id'
            />
          )}
          <TextInput
            source='name'
            label='Name'
            type='text'
            validate={required()}
          />
          {props.id && (
            <TextField
              source='refNumber'
              label='Reference Number'
            />
          )}
          <TextInput
            source='address'
            label='Address'
            type='text'
            validate={required()}
          />
          <SelectInput
            choices={createChoices(ESecurityLevel)}
            source='securityLevel'
            validate={required()}
          />
          <CloudinaryField
            source='photo'
            label='Image Photo'
            required
          />
          <CloudinaryField
            source='logo'
            label='Property Logo'
            required
          />
          {props.id && (
            <>
              <DateField
                source='createdAt'
                label='Created at'
                showTime
              />
              <DateField
                source='updatedAt'
                label='Updated at'
                showTime
              />
            </>
          )}
          <BooleanInput
            source='sendSMS'
            label='Send SMS'
            defaultValue={true}
          />
          <NumberInput
            source='autoSignOutTimeout'
            label='Auto sign out timeout (hours)'
            validate={required()}
            defaultValue={14}
          />
          <BooleanInput
            source='extraField'
            label='Enable Extra Field'
            defaultValue={false}
          />
          <TextInput
            source='extraFieldLabel'
            label='Extra Field Label'
            type={'text'}
          />
          <BooleanInput
            source='customBranding'
            label='Custom Branding'
            defaultValue={false}
          />
          <TextInput
            source="branding.mainColor"
            label="Main Color"
            type={'color'}
          />
          <TextInput
            source='branding.backgroundColor'
            label='Background Color'
            type={'color'}
          />
        </FormTab>
        {props.id && <AdminsTab />}
        {props.id && <ManagersTab />}
        {process.env.NODE_ENV === 'development' && props.id && <FlatsTab />}
        {props.id && <DevicesTab />}
        {props.id && adminData?.type === 'admins' && <NotificationsTab />}
      </TabbedForm>
    </Change>
  )
}
export default PropertyChange
