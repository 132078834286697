import React, { useCallback } from 'react'

import {
  useInput,
  showNotification,
  TextInput, required
} from 'react-admin'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import { Image } from 'cloudinary-react'
import { ButtonGroup } from '@material-ui/core'
import { Delete } from '@material-ui/icons'

const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME
const uploadPreset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET

const useStyles = makeStyles(
  theme => ({
    field: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(2),
      width: theme.spacing(32)
    },
    image: {
      marginBottom: theme.spacing(2)
    },
    fullWidth: {
      width: '100%'
    },
    deleteButton: {
      background: theme.palette.error.dark,
      '&:hover': {
        background: theme.palette.error.main
      }
    }
  })
)

const CloudinaryField = (props) => {
  const classes = useStyles()
  const {
    input: { value, onChange }
  } = useInput(props)
  const openUploadWidget = useCallback(() => {
    try {
      const myWidget = window.cloudinary.createUploadWidget(
        {
          cloudName,
          uploadPreset,
          multiple: false,
          maxFileSize: 10000000 // 10MB
        },
        (error, result) => {
          const successfullyUploaded = !error && result && result.event === 'success'
          if (successfullyUploaded) {
            const publicUrl = result.info.secure_url
            const publicId = result.info.public_id
            onChange({
              publicUrl, publicId
            })
          }
        }
      )
      myWidget.open()
    } catch (error) {
      console.error(error)
      props.showNotification('Cannot open upload widget', 'error')
    }
  }, [onChange, props])

  return (
    <div className={classes.field}>
      <TextInput
        source={props.source}
        label={props.label}
        disabled
        InputLabelProps={{ shrink: !!value }} // otherwise label won't shrink when display is set by format prop
        format={value => value?.publicUrl}
        fullWidth
        validate={props.required && required()}
      />
      {
        value.publicUrl
          ? (
            <Image
              className={classes.image}
              cloudName={cloudName}
              publicId={value.publicUrl}
              width='256'
              crop='scale'
            />
            )
          : null
      }
      <ButtonGroup disableElevation variant='contained' color='primary'>
        <Button
          onClick={openUploadWidget}
          className={classes.fullWidth}
        >
          {value ? 'Change photo' : 'Upload photo'}
        </Button>
        {!props.required && (
          <Button
            size='small'
            onClick={() => onChange(undefined)}
            className={classes.deleteButton}
          >
            <Delete />
          </Button>
        )}
      </ButtonGroup>

    </div>
  )
}

export default connect(null, { showNotification })(CloudinaryField)
