export const EMobileUsersStatus = {
  Pending: 'pending',
  Archived: 'archived',
  Verified: 'verified'
}

export const mobileUsersStatuses = [
  {
    id: EMobileUsersStatus.Pending,
    name: 'Pending'
  },
  {
    id: EMobileUsersStatus.Archived,
    name: 'Archived'
  },
  {
    id: EMobileUsersStatus.Verified,
    name: 'Verified'
  }
]
